import { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./Mapa.css";
import { Container } from "react-bootstrap";
import axios from "axios";
import config from "./../../../../../config.json";
const apiUrl = config.apiUrl;
import * as Sentry from "@sentry/react";

const MapComponent = () => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    // Configuración inicial del mapa
    const map = L.map(mapContainerRef.current).setView(
      [-35.443939, -60.884524],
      13
    );

    // Capa de azulejos del mapa
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {}).addTo(
      map
    );

    // Función para agregar un marcador en el mapa con ícono personalizado y color
    const addMarkerToMap = (latitude, longitude, Randomcolor, id, titulo) => {
      const customIcon = L.divIcon({
        className: "custom-icon",
        iconSize: [16, 16],
        iconAnchor: [8, 16],
        popupAnchor: [0, -32],
        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${Randomcolor}"><path d="M12 0c-4.243 0-8 3.354-8 8 0 7.364 8 16 8 16s8-8.636 8-16c0-4.646-3.757-8-8-8zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>`,
      });

      const marker = L.marker([latitude, longitude], { icon: customIcon })
        .addTo(map)
        .bindTooltip(titulo); // Vincular un popup con el título

      marker.on("click", () => {
        window.location.href = `https://inmos.com.ar/inmueble/${id}`;
      });
    };

    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl + "/api/inmuebles/coordenadas");

        response.data.data.forEach((item) => {
          const { latitude, longitude } = item.localidad_maps;
          const temporal = item.temporal;
          const randomColor = temporal ? "#202529" : "#e3bb7b";
          addMarkerToMap(
            latitude,
            longitude,
            randomColor,
            item.id,
            item.titulo
          );
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error("Hubo un error con la petición:", error);
      }
    };

    fetchData();

    return () => {
      map.remove();
    };
  }, []);

  return (
    <Container
      fluid
      style={{ backgroundColor: "#202529", textAlign: "center" }}
      className="p-3"
    >
      <h2 className="titulo">INMUEBLES EN EL MAPA</h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "start",
          marginTop: 50,
        }}
        className="row"
      >
        <div
          style={{ marginRight: "20px", marginTop: "5px" }}
          className="col-md d-flex align-items-center justify-content-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#a18f63"
            style={{ height: "24px", width: "24px", verticalAlign: "middle" }}
          >
            <path d="M12 0c-4.243 0-8 3.354-8 8 0 7.364 8 16 8 16s8-8.636 8-16c0-4.646-3.757-8-8-8zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
          </svg>
          <span style={{ marginLeft: "5px", color: "white" }}>
            Alquileres temporales
          </span>
        </div>
        <div
          style={{ marginRight: "20px", marginTop: "5px" }}
          className="col-md d-flex align-items-center justify-content-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#aab7b8"
            style={{ height: "24px", width: "24px", verticalAlign: "middle" }}
          >
            <path d="M12 0c-4.243 0-8 3.354-8 8 0 7.364 8 16 8 16s8-8.636 8-16c0-4.646-3.757-8-8-8zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
          </svg>
          <span style={{ marginLeft: "5px", color: "white" }}>
            Alquileres destacados
          </span>
        </div>
        <div
          className="col-md d-flex align-items-center justify-content-center"
          style={{ marginTop: "5px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#e3bb7b"
            style={{ height: "24px", width: "24px", verticalAlign: "middle" }}
          >
            <path d="M12 0c-4.243 0-8 3.354-8 8 0 7.364 8 16 8 16s8-8.636 8-16c0-4.646-3.757-8-8-8zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
          </svg>
          <span style={{ marginLeft: "5px", color: "white" }}>
            Alquileres no temporales
          </span>
        </div>
      </div>

      <div
        className="map-container"
        style={{ marginTop: "50px" }}
        ref={mapContainerRef}
      />
    </Container>
  );
};

export default MapComponent;
