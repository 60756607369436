import { Container, Row, Col } from "react-bootstrap";
import {
  BiMap,
  BiPhone,
  BiMailSend,
  BiEnvelope,
  BiSearch,
} from "react-icons/bi";
import { FaInstagram } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="custom-footer">
      <div className="wave-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="wave"
        >
          <path
            fill="#d5d8dc"
            d="M0,160L40,160C80,160,160,160,240,176C320,192,400,224,480,224C560,224,640,192,720,170.7C800,149,880,139,960,133.3C1040,128,1120,128,1200,133.3C1280,139,1360,149,1400,154.7L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          ></path>
        </svg>
      </div>
      <Container style={{ color: "rgb(32, 37, 41)", textAlign: "center" }}>
        <Row>
          <Col md={4}>
            <p style={{ textTransform: "uppercase", fontSize: 24 }}>Contacto</p>
            <ul className="list-unstyled">
              <li>
                <BiMap style={{ marginRight: 10, fontSize: 20 }} />9 de Julio,
                Buenos Aires
              </li>
              <li>
                <BiPhone style={{ marginRight: 10, fontSize: 20 }} />
                (+54) 2317 529670
              </li>
              <li>
                <BiMailSend style={{ marginRight: 10, fontSize: 20 }} />
                InmoS@gmail.com
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <p style={{ textTransform: "uppercase", fontSize: 24 }}>
              Estructura
            </p>
            <ul className="list-unstyled">
              <li>
                <BiSearch style={{ marginRight: 10, fontSize: 20 }} />
                Busqueda
              </li>
              <li>
                <BiMap style={{ marginRight: 10, fontSize: 20 }} />
                Mapa
              </li>
              <li>
                <BiEnvelope style={{ marginRight: 10, fontSize: 20 }} />
                Testimonios
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <p
              style={{
                textTransform: "uppercase",
                fontSize: 24,
              }}
            >
              Siguenos
            </p>
            <ul className="list-unstyled">
              <li>
                <FaInstagram style={{ marginRight: 10, fontSize: 20 }} />
                <a
                  href="https://www.instagram.com/inmosapp?igsh=bHBrZnVmemt1eDcx"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "rgb(32, 37, 41)" }}
                >
                  Instagram
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <p
              className="text-center"
              style={{
                fontSize: 20,

                color: "rgb(32, 37, 41)",
              }}
            >
              &copy; {new Date().getFullYear()} InmoS. Todos los derechos
              reservados.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
