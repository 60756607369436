import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Container, Button, Nav, Navbar, Col, Card } from "react-bootstrap";
import {
  FaUser,
  FaCity,
  FaPowerOff,
  FaGift,
  FaShoppingCart,
} from "react-icons/fa";
import "./panelusuario.css";
import InmueblesComponent from "../../assets/components/PanelUsuario/InmueblesComponent/InmueblesComponent";
import Perfil from "../../assets/components/PanelUsuario/Perfil/Perfil";
import axios from "axios";
import config from "./../../../config.json";
import * as Sentry from "@sentry/react";
const apiUrl = config.apiUrl;

const Dashboard = () => {
  const location = useLocation();

  const navigate = useNavigate();
  var [data, setData] = useState(location.state?.data);
  var [jwt, setJWT] = useState(location.state?.jwt);
  const [userStatePay, setUserStatePay] = useState("No pago");

  const buttonText = data?.nombre || data?.razonsocial;
  const tipoUser = data?.tipo;

  const phoneNumber = "2317512310";

  const handleMessageWhatsApp = (plan, type) => {
    // Mensaje predeterminado
    const message = `¡Hola! Estoy interesado en el plan ${plan} para mi usuario ${data?.email} del tipo ${type}.`;

    // URL para abrir WhatsApp con el mensaje predefinido
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.location.href = whatsappUrl;
  };

  useEffect(() => {
    const tokenFromCookie = Cookies.get("jwt");

    setActiveComponent("inmuebles");

    if (!tokenFromCookie) {
      Cookies.remove("username");
      navigate("/");
      return; // Agrega un return aquí para evitar la ejecución del código restante si no hay token
    }

    const jwtData = {
      token: tokenFromCookie,
    };

    axios
      .post(apiUrl + "/api/auth/checkJWT", jwtData, {
        headers: {
          Authorization: `Bearer ${tokenFromCookie}`,
        },
      })
      .then((response) => {
        if (response.data.blocked) {
          navigate("/userblock");
          return;
        }

        setData(response.data);
        setUserStatePay(response.data.estado);

        if (!jwt) {
          setJWT(tokenFromCookie);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        if (error.response.status === 401) {
          navigate("/userblock");
        }
      });
  }, [jwt, navigate]); // Dependencias específicas necesarias para el efecto

  const [activeComponent, setActiveComponent] = useState(null);

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  const handleLogout = () => {
    Cookies.remove("jwt");
    Cookies.remove("username");
    navigate("/");
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand
            style={{
              color: "#fcd088",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            href="/"
          >
            InmoS
          </Navbar.Brand>

          <div className="chip-avatar">
            <span className="chip-avatar-text">{data?.tipo}</span>
          </div>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ backgroundColor: "#fcd088", border: "1px solid black" }}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav className="me-auto ">
              <a
                className={`inicio ${
                  activeComponent === "inmuebles" ? "active" : ""
                }  pl-3 pr-3 `}
                style={{
                  border: "none",
                  color: "white",
                  pointerEvents: userStatePay === "No pago" ? "none" : "",
                }}
                onClick={() => handleButtonClick("inmuebles")}
                aria-disabled={userStatePay === "No pago"}
              >
                <FaCity className="pl-3" style={{ marginRight: 10 }} />
                Inmuebles
              </a>

              <a
                className={`inicio ${
                  activeComponent === "perfil" ? "active" : ""
                } pl-3 pr-3`}
                style={{
                  border: "none",
                  color: "white",
                  pointerEvents: userStatePay === "No pago" ? "none" : "",
                }}
                onClick={() => handleButtonClick("perfil")}
              >
                <FaUser style={{ marginRight: 10 }} />
                Perfil
              </a>
            </Nav>
            <Nav>
              <a
                className={`inicio ${
                  activeComponent === "cerrar-sesion" ? "active" : ""
                }`}
                style={{ border: "1px solid #fcd088" }}
                onClick={() => {
                  handleButtonClick("cerrar-sesion");
                  handleLogout(); // Llamada a la función para cerrar sesión
                }}
              >
                <FaPowerOff style={{ marginRight: 10 }} />
                Cerrar Sesión
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid className="mt-2" style={{ color: "white" }}>
        {!activeComponent && (
          <Col
            style={{
              textAlign: "center",
              marginTop: "5px",
              marginLeft: "15px",
              fontSize: "30px",
              color: "white",
            }}
          >
            ¡Hola! Bienvenido,
            <Button
              className={`btn1 ${activeComponent === "email" ? "active" : ""}`}
              variant="outline-dark"
              style={{ border: "none", fontSize: "30px", marginBottom: "22px" }}
              disabled
              block
            >
              {buttonText}
            </Button>{" "}
          </Col>
        )}
        {activeComponent === "inmuebles" && (
          <InmueblesComponent iduser={data?.id} jwt={jwt} />
        )}
        {activeComponent === "perfil" && <Perfil iduser={data?.id} jwt={jwt} />}
      </Container>

      <div className="container">
        {tipoUser === "Particular" && userStatePay == "No pago" ? (
          <div className="row d-flex justify-content-center">
            <p
              className="text-center"
              style={{ fontSize: "22px", color: "white" }}
            >
              ¡Elegí el plan que mejor se adapte a tus necesidades!
            </p>

            <div className="col-md-6 mt-3" style={{ maxWidth: "450px" }}>
              <Card className="m-auto">
                <h5
                  className="text-center m-auto w-50"
                  style={{
                    backgroundColor: "#fcd088",
                    color: "white",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Básico
                </h5>
                <Card.Body className="text-center">
                  <Card.Title className="border-bottom pb-2">
                    <span
                      style={{
                        fontSize: "32px",
                        color: "#fcd088",
                        fontWeight: "bold",
                      }}
                    >
                      $8.000
                    </span>{" "}
                    / por mes
                    {/* <div
                      class="chip primary w-50 mx-auto"
                      style={{ marginTop: "15px" }}
                    >
                      <FaGift
                        className="pl-3"
                        style={{ marginRight: "5px", fontSize: "15px" }}
                      />
                      ¡Primer mes gratis!
                    </div> */}
                  </Card.Title>

                  <Card.Text
                    className="text-muted"
                    style={{ fontWeight: "500" }}
                  >
                    <br />
                    <strong>1</strong> publicación por mes
                    <br />
                    <br />
                    Alquiler permanente o temporal
                    <br />
                    <br />
                    Máxima visibilidad
                    <br />
                    <br />
                    Duración <strong>30 días</strong>
                  </Card.Text>
                  <Button
                    style={{
                      border: "1px solid #fcd088",
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "25px",
                    }}
                    className="plan"
                    onClick={() =>
                      handleMessageWhatsApp("Básico", "Particular")
                    }
                  >
                    <FaShoppingCart
                      size={20}
                      style={{ marginRight: "10px", marginBottom: "5px" }}
                    ></FaShoppingCart>
                    Elegir plan
                  </Button>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-6 mt-3" style={{ maxWidth: "450px" }}>
              <Card className="m-auto">
                <h5
                  className="text-center m-auto w-50"
                  style={{
                    backgroundColor: "#fcd088",
                    color: "white",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Premium
                </h5>
                <Card.Body className="text-center">
                  <Card.Title className="border-bottom pb-2">
                    <span
                      style={{
                        fontSize: "32px",
                        color: "#fcd088",
                        fontWeight: "bold",
                      }}
                    >
                      $14.990
                    </span>{" "}
                    / por mes
                    {/* <div
                      class="chip primary w-50 mx-auto"
                      style={{ marginTop: "15px" }}
                    >
                      <FaGift
                        className="pl-3"
                        style={{ marginRight: "5px", fontSize: "15px" }}
                      />
                      ¡Primer mes gratis!
                    </div> */}
                  </Card.Title>

                  <Card.Text
                    className="text-muted"
                    style={{ fontWeight: "500" }}
                  >
                    <br />
                    Publicaciones{" "}
                    <span style={{ fontWeight: "bold" }}>ilimitadas</span>
                    <br />
                    <br />
                    Alquiler permanente o temporal
                    <br />
                    <br />
                    Máxima visibilidad
                    <br />
                    <br />
                    Duración <strong>30 días</strong>
                  </Card.Text>
                  <Button
                    style={{
                      border: "1px solid #fcd088",
                      backgroundColor: "white",
                      color: "black",
                      borderRadius: "25px",
                    }}
                    className="plan"
                    onClick={() =>
                      handleMessageWhatsApp("Premium", "Particular")
                    }
                  >
                    <FaShoppingCart
                      size={20}
                      style={{ marginRight: "10px", marginBottom: "5px" }}
                    ></FaShoppingCart>
                    Elegir plan
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>
        ) : tipoUser === "Inmobiliaria" && userStatePay === "No pago" ? (
          <div className="row d-flex justify-content-center mb-4">
            <p
              className="text-center"
              style={{ fontSize: "22px", color: "white" }}
            >
              ¡Elegí el plan que mejor se adapte a tus necesidades!
            </p>
            <div className="col-md-4 mt-3" style={{ maxWidth: "450px" }}>
              <Card className="m-auto" style={{ height: "475px" }}>
                <h5
                  className="text-center m-auto w-50"
                  style={{
                    backgroundColor: "#fcd088",
                    color: "white",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Básico
                </h5>
                <Card.Body className="text-center">
                  <Card.Title className="border-bottom pb-2">
                    <span
                      style={{
                        fontSize: "32px",
                        color: "#fcd088",
                        fontWeight: "bold",
                      }}
                    >
                      $8.000
                    </span>{" "}
                    / por mes
                    {/* <div
                      class="chip primary w-50 mx-auto"
                      style={{ marginTop: "15px" }}
                    >
                      <FaGift
                        className="pl-3"
                        style={{ marginRight: "5px", fontSize: "15px" }}
                      />
                      ¡Primer mes gratis!
                    </div> */}
                  </Card.Title>

                  <Card.Text
                    className="text-muted"
                    style={{ fontWeight: "500" }}
                  >
                    <br />
                    <strong>1</strong> publicaciones por mes
                    <br />
                    <br />
                    Alquiler permanente o temporal
                    <br />
                    <br />
                    Máxima visibilidad
                    <br />
                    <br />
                    Duración <strong>30 días</strong>
                  </Card.Text>
                  <Button
                    style={{
                      border: "1px solid #fcd088",
                      backgroundColor: "white",
                      color: "black",
                      width: "200px",
                      borderRadius: "25px",
                    }}
                    className="mt-1 plan"
                    onClick={() =>
                      handleMessageWhatsApp("Básico", "Inmobliaria")
                    }
                  >
                    <FaShoppingCart
                      size={20}
                      style={{ marginRight: "10px", marginBottom: "5px" }}
                    ></FaShoppingCart>
                    Elegir plan
                  </Button>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-4 mt-3" style={{ maxWidth: "450px" }}>
              <Card className="m-auto" style={{ height: "475px" }}>
                <h5
                  className="text-center m-auto w-50"
                  style={{
                    backgroundColor: "#fcd088",
                    color: "white",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Premium
                </h5>
                <Card.Body className="text-center">
                  <Card.Title className="border-bottom pb-2">
                    <span
                      style={{
                        fontSize: "32px",
                        color: "#fcd088",
                        fontWeight: "bold",
                      }}
                    >
                      $14.990
                    </span>{" "}
                    / por mes
                    {/* <div
                      class="chip primary w-50 mx-auto"
                      style={{ marginTop: "15px" }}
                    >
                      <FaGift
                        className="pl-3"
                        style={{ marginRight: "5px", fontSize: "15px" }}
                      />
                      ¡Primer mes gratis!
                    </div> */}
                  </Card.Title>

                  <Card.Text
                    className="text-muted"
                    style={{ fontWeight: "500" }}
                  >
                    <br />
                    Publicaciones <strong>ilimitadas</strong>
                    <br />
                    <br />
                    Alquiler permanente o temporal
                    <br />
                    <br />
                    Máxima visibilidad
                    <br />
                    <br />
                    Duración <strong>30 días</strong>
                  </Card.Text>
                  <Button
                    style={{
                      border: "1px solid #fcd088",
                      backgroundColor: "white",
                      color: "black",
                      width: "200px",
                      borderRadius: "25px",
                    }}
                    className="mt-1 plan"
                    onClick={() =>
                      handleMessageWhatsApp("Intermedio", "Inmobiliaria")
                    }
                  >
                    <FaShoppingCart
                      size={20}
                      style={{ marginRight: "10px", marginBottom: "5px" }}
                    ></FaShoppingCart>
                    Elegir plan
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>
        ) : tipoUser === "Hotel" && userStatePay === "No pago" ? (
          <div className="row d-flex justify-content-center mb-4">
            <p className="text-center text-muted" style={{ fontSize: "22px" }}>
              ¡Elegí el plan que mejor se adapte a tus necesidades!
            </p>
            <div className="col-md-6 mt-3" style={{ maxWidth: "450px" }}>
              <Card className="m-auto" style={{ height: "475px" }}>
                <h5
                  className="text-center m-auto w-50"
                  style={{
                    backgroundColor: "#fcd088",
                    color: "white",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Básico
                </h5>
                <Card.Body className="text-center">
                  <Card.Title className="border-bottom pb-2">
                    <span
                      style={{
                        fontSize: "32px",
                        color: "#fcd088",
                        fontWeight: "bold",
                      }}
                    >
                      $11.990
                    </span>{" "}
                    / por mes
                    {/* <div
                      class="chip primary w-50 mx-auto"
                      style={{ marginTop: "15px" }}
                    >
                      <FaGift
                        className="pl-3"
                        style={{ marginRight: "5px", fontSize: "15px" }}
                      />
                      ¡Primer mes gratis!
                    </div> */}
                  </Card.Title>

                  <Card.Text
                    className="text-muted"
                    style={{ fontWeight: "500" }}
                  >
                    <br />
                    Hasta <strong>5</strong> unidades funcionales
                    <br />
                    <br />
                    Máxima visibilidad
                    <br />
                    <br />
                    Duración <strong>30 días</strong>
                  </Card.Text>
                  <Button
                    style={{
                      border: "1px solid #fcd088",
                      backgroundColor: "white",
                      color: "black",
                      width: "200px",
                      borderRadius: "25px",
                    }}
                    className="mt-1 plan"
                    onClick={() => handleMessageWhatsApp("Básico", "Hotel")}
                  >
                    <FaShoppingCart
                      size={20}
                      style={{ marginRight: "10px", marginBottom: "5px" }}
                    ></FaShoppingCart>
                    Elegir plan
                  </Button>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-6 mt-3" style={{ maxWidth: "450px" }}>
              <Card className="m-auto" style={{ height: "475px" }}>
                <h5
                  className="text-center m-auto w-50"
                  style={{
                    backgroundColor: "#fcd088",
                    color: "white",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  Premium
                </h5>
                <Card.Body className="text-center">
                  <Card.Title className="border-bottom pb-2">
                    <span
                      style={{
                        fontSize: "32px",
                        color: "#fcd088",
                        fontWeight: "bold",
                      }}
                    >
                      $18.990
                    </span>{" "}
                    / por mes
                    {/* <div
                      class="chip primary w-50 mx-auto"
                      style={{ marginTop: "15px" }}
                    >
                      <FaGift
                        className="pl-3"
                        style={{ marginRight: "5px", fontSize: "15px" }}
                      />
                      ¡Primer mes gratis!
                    </div> */}
                  </Card.Title>

                  <Card.Text
                    className="text-muted"
                    style={{ fontWeight: "500" }}
                  >
                    <br />
                    Publicaciones <strong>ilimitadas</strong>
                    <br />
                    <br />
                    Hotel <strong>destacado</strong> (aparece recomendado por el
                    portal)
                    <br />
                    <br />
                    Máxima visibilidad
                    <br />
                    <br />
                    Incluye contenido audiovisual
                    <br />
                    <br />
                    Duración <strong>30 días</strong>
                  </Card.Text>
                  <Button
                    style={{
                      border: "1px solid #fcd088",
                      backgroundColor: "white",
                      color: "black",
                      width: "200px",
                      borderRadius: "25px",
                    }}
                    className="mt-1 plan"
                    onClick={() => handleMessageWhatsApp("Premium", "Hotel")}
                  >
                    <FaShoppingCart
                      size={20}
                      style={{ marginRight: "10px", marginBottom: "5px" }}
                    ></FaShoppingCart>
                    Elegir plan
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>
        ) : (
          // Contenido que se muestra para otros valores de tipoUser
          <p></p>
        )}
      </div>
    </>
  );
};

export default Dashboard;
