/* eslint-disable react/prop-types */
import Pagination from "react-bootstrap/Pagination";
import "./Paginacion.css";

const PaginationBar = ({ pages, activePage, setActivePage }) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= pages) {
      setActivePage(page);
    }
  };

  const renderPaginationItems = () => {
    const items = [];

    // Flecha hacia atrás
    items.push(
      <Pagination.Prev
        key="prev"
        disabled={activePage === 1}
        onClick={() => handlePageChange(activePage - 1)}
      />
    );

    let startPage = Math.max(1, activePage - 3);
    let endPage = Math.min(pages, activePage + 3);

    // Asegúrate de mostrar exactamente 7 páginas si es posible
    if (activePage <= 4) {
      endPage = Math.min(7, pages);
    } else if (activePage > pages - 4) {
      startPage = Math.max(pages - 6, 1);
    }

    // Números de página
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === activePage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Flecha hacia adelante
    items.push(
      <Pagination.Next
        key="next"
        disabled={activePage === pages}
        onClick={() => handlePageChange(activePage + 1)}
      />
    );

    return items;
  };

  return (
    <Pagination className="pagination-bar">
      {renderPaginationItems()}
    </Pagination>
  );
};

export default PaginationBar;
