import { Container, Carousel, Card } from "react-bootstrap";
import { IoStar } from "react-icons/io5";
import "./Opiniones.css";
import { FaLink } from "react-icons/fa";

const Opiniones = () => {
  const opinions = [
    {
      id: 1,
      name: "Silvina S.",
      company: "",
      opinion:
        "Muy conforme con el servicio que brindan, muy atentos a los requerimientos o consultas . Volveré a publicar.",
      puntaje: 5,
      link: "https://g.co/kgs/pEfMUod",
    },
    {
      id: 2,
      name: "Nahuel Alberti",
      company: "",
      opinion: "Atencion 10/10. Respuesta super rapida!",
      puntaje: 5,
      link: "https://g.co/kgs/mN65Xth",
    },
    {
      id: 3,
      name: "Elisa Vide",
      company: "",
      opinion:
        "Excelente la página y por sobre todo muy buena disposición de sus administradores.",
      puntaje: 5,
      link: "https://g.co/kgs/N2rMBmE",
    },
    {
      id: 4,
      name: "Claudia Gomez",
      company: "",
      opinion:
        "Hice una consulta para saber a quien le corresponde sobre un arreglo en una propiedad que alquila mí hija estudiante en la edad de La Plata,muy atentamente me informaron sobre el tema.Ultragradecida.",
      puntaje: 5,
      link: "https://g.co/kgs/pv5Hbzi",
    },
    {
      id: 5,
      name: "Carolina Otalvaro gallego",
      company: "",
      opinion:
        "Muy atentos, diligentes y resolutivos con todo, seguiré contactándolos para hacer mas publicaciones de alquiler.",
      puntaje: 5,
      link: "https://g.co/kgs/qj6BkEE",
    },
    {
      id: 6,
      name: "Macarena Y Zurieta",
      company: "",
      opinion: "encontré el depto que queria! gracias 🤩🤩🤩",
      puntaje: 5,
      link: "https://g.co/kgs/hYhkiE2",
    },
    {
      id: 7,
      name: "Joselin Flores",
      company: "",
      opinion: "Muy amables en atención",
      puntaje: 5,
      link: "https://g.co/kgs/Mbj6eiJ",
    },
    {
      id: 8,
      name: "Juan Carlos Benitez",
      company: "",
      opinion: "Muy buenos profesionales",
      puntaje: 5,
      link: "https://g.co/kgs/d5iRin2",
    },
    {
      id: 9,
      name: "Kevin Campoverde",
      company: "",
      opinion: "",
      puntaje: 4,
      link: "https://g.co/kgs/jgG5hSm",
    },
    {
      id: 10,
      name: "Martina Etcheto",
      company: "",
      opinion: "",
      puntaje: 5,
      link: "https://g.co/kgs/piqs4FE",
    },
    {
      id: 11,
      name: "Luciano Bracco",
      company: "",
      opinion: "",
      puntaje: 5,
      link: "https://g.co/kgs/AyHZbBW",
    },
  ];

  return (
    <Container className="p-3 " fluid style={{ backgroundColor: "#202529" }}>
      <h2 className="titulo">TESTIMONIOS</h2>
      <Carousel
        className="opinion-carousel"
        controls={false}
        style={{ padding: 10 }}
      >
        {opinions.map((opinion) => (
          <Carousel.Item key={opinion.id}>
            <Card
              className="opinion-card mx-auto"
              style={{
                backgroundColor: "transparent",
                border: "none",
                textAlign: "center",
              }}
            >
              <Card.Body style={{ textAlign: "center" }}>
                <div className="d-flex align-items-center mb-3">
                  <img
                    src={`https://via.placeholder.com/100?text=${opinion.name[0]}`}
                    alt={opinion.name}
                    className="rounded-circle"
                    style={{ width: "50px", height: "50px", marginRight: 10 }}
                  />
                  <div>
                    <Card.Title className="mb-0">{opinion.name}</Card.Title>
                    {opinion.company && (
                      <Card.Subtitle className="text-muted">
                        {opinion.company}
                      </Card.Subtitle>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  {[...Array(5)].map((_, index) => (
                    <IoStar
                      key={index}
                      color={index < opinion.puntaje ? "#ffc107" : "#e4e5e9"}
                      style={{ marginRight: 5 }}
                    />
                  ))}
                  <span style={{ color: "white" }}>({opinion.puntaje}/5)</span>
                </div>
                <Card.Text className="mb-2" style={{ textAlign: "left" }}>
                  {opinion.opinion != "" ? <>“{opinion.opinion}”</> : <></>}
                </Card.Text>
                <Card.Text
                  className="mb-2"
                  style={{ textAlign: "left", cursor: "pointer" }}
                  onClick={() => {
                    window.open(opinion.link, "_blank");
                  }}
                >
                  Referencia
                  <FaLink style={{ marginLeft: 10 }} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default Opiniones;
