import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Image } from "react-bootstrap"; // Importa componentes de React Bootstrap
import axios from "axios";
import config from "./../../../config.json";
import { FaUser, FaBuilding, FaGoogle, FaHouseUser } from "react-icons/fa";
import Imagen from "../../assets/images/favicon.png";

const apiUrl = config.apiUrl;

const cardStyle = {
  width: "auto",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  margin: "0 auto",
  marginTop: "100px",
};

let email = "";

const GoogleAuthCallback = () => {
  const [auth, setAuth] = useState();
  // eslint-disable-next-line no-unused-vars
  const [selection, setSelection] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location) {
      return;
    }

    const { search } = location;
    axios({
      method: "GET",
      url: `${apiUrl}/api/auth/google/callback${search}`,
    })
      .then((res) => res.data)
      .then((authData) => {
        // Save JWT in cookies
        document.cookie = `jwt=${authData.jwt}; path=/`;

        setAuth(authData);

        if (authData.user.blocked) {
          navigate("/userblock");
          return;
        }

        if (authData.user.tipo && !authData.user.blocked) {
          navigate("/panelusuario", {
            state: { data: authData.user, jwt: authData.jwt },
          });
        }

        email = authData.user.email;
      });
  }, [location]);

  return (
    <Container className="d-flex justify-content-center align-items-center h-100 w-50">
      {auth ? (
        <div style={cardStyle}>
          <Col className="mb-2">
            <Image src={Imagen} roundedCircle className="w-25 h-25" />
          </Col>
          <h1 style={{ color: "#fcd088", fontWeight: "bold" }}>AlquilARG</h1>
          <h5 className="text-secondary">Registro con Google</h5>
          <h5>
            <FaGoogle></FaGoogle>
          </h5>
          <h6 className="text-secondary">Paso 1 de 2</h6>
          {selection === null ? (
            <>
              <div className="font-weight-bold mb-4 mt-5">
                <h5 className="text-dark" style={{ color: "black" }}>
                  Elige el tipo de usuario
                </h5>
              </div>
              <Row className="d-flex justify-content-center">
                <Col md={4} className="d-flex justify-content-center">
                  <Button
                    variant="dark button-39"
                    onClick={() => {
                      navigate("/step2/Particular?email=" + email);
                    }}
                    block
                    className="d-flex align-items-center"
                  >
                    <FaUser className="mr-2" style={{ marginRight: "10px" }} />
                    Particular
                  </Button>
                </Col>
                <Col md={4} className="d-flex justify-content-center">
                  <Button
                    variant="dark button-39"
                    onClick={() => {
                      navigate("/step2/Inmobiliaria");
                    }}
                    block
                    className="d-flex align-items-center"
                  >
                    <FaHouseUser
                      className="mr-2"
                      style={{ marginRight: "10px" }}
                    />
                    Inmobiliaria
                  </Button>
                </Col>

                <Col md={4} className="d-flex justify-content-center">
                  <Button
                    variant="dark button-39"
                    onClick={() => {
                      navigate("/step2/Hotel");
                    }}
                    block
                    className="d-flex align-items-center"
                  >
                    <FaBuilding
                      className="mr-2"
                      style={{ marginRight: "10px" }}
                    />
                    Hotel
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <div style={{ fontWeight: "bold" }}>Seleccionaste: {selection}</div>
          )}
        </div>
      ) : (
        <div style={cardStyle}>Conectando con el proveedor...</div>
      )}
    </Container>
  );
};

export default GoogleAuthCallback;
