import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./Mapa.css";
import { Container } from "react-bootstrap";
import { FaMap, FaMapPin } from "react-icons/fa";

const MapComponentIndividual = ({
  location,
  latitude,
  longitude,
  localidad,
}) => {
  const mapContainerRef = useRef(null); // Referencia para el contenedor del mapa

  useEffect(() => {
    // Crear un nuevo contenedor para el mapa
    const mapContainer = document.createElement("div");
    mapContainer.style.height = "100%";
    mapContainerRef.current.appendChild(mapContainer);

    // Inicializar el mapa
    const map = L.map(mapContainer).setView([latitude, longitude], 15);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "",
    }).addTo(map);

    // Agregar marcador
    const customIcon = L.divIcon({
      className: "custom-icon",
      iconSize: [40, 40],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
      html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fcd088"><path d="M12 0c-4.243 0-8 3.354-8 8 0 7.364 8 16 8 16s8-8.636 8-16c0-4.646-3.757-8-8-8zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>`,
    });

    L.marker([latitude, longitude], { icon: customIcon }).addTo(map);

    return () => {
      // Limpiar el mapa
      if (map) {
        map.remove();
      }

      // Asegurarse de que el contenedor del mapa y su hijo existan antes de intentar eliminarlo
      if (mapContainerRef.current && mapContainer) {
        mapContainerRef.current.removeChild(mapContainer);
      }
    };
  }, [latitude, longitude]);

  return (
    <>
      <p
        style={{
          fontSize: "20px",
          marginTop: "20px",
          marginBottom: "0px",
          fontWeight: "",
          color: "white",
        }}
      >
        <FaMapPin style={{ color: "#fcd088" }} /> {location}, {localidad}
      </p>
      <div
        ref={mapContainerRef}
        className="map-container2"
        style={{ padding: "0px", marginTop: "5px" }}
      />
    </>
  );
};

export default MapComponentIndividual;
