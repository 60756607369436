/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import {
  Card,
  Button,
  Pagination,
  Container,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaTrash, FaStar, FaSearch } from "react-icons/fa";
import axios from "axios";
import "./Inmuebles.css";
import Swal from "sweetalert2";
import config from "./../../../../../config.json";
import imagen from "../../../../../public/images/no_result_inmueble.png";
import Cookies from "js-cookie";
import PaginationBar from "../../FiltradoPage/Paginacion/Paginacion";
import * as Sentry from "@sentry/react";
const apiUrl = config.apiUrl;

const Inmuebles = ({ jwt }) => {
  const [isLoading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl + `/api/inmuebles`);
        const propertiesData = response.data.data.map((property) => ({
          id: property.id,
          ...property,
        }));
        setProperties(propertiesData);
        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        console.error("Hubo un error con la petición:", error);
      }
    };

    fetchData();
  }, []);

  const handleDeleteProperty = async (id) => {
    try {
      await axios.delete(apiUrl + `/api/inmuebles/deleteadmin/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      // Actualizar la lista de propiedades después de eliminar una
      setProperties((prevProperties) =>
        prevProperties.filter((property) => property.id !== id)
      );
    } catch (error) {
      Sentry.captureException(error);
      console.error("Hubo un error al eliminar el inmueble:", error);
    }
  };

  const successMessage = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Está seguro que desea eliminar el inmueble?",
        text: "¡No puedes revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleDeleteProperty(id);
          swalWithBootstrapButtons.fire("Inmueble eliminado correctamente");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire("Cancelado");
        }
      });
  };

  const destacarInmueble = async (id) => {
    const jwt = Cookies.get("jwt");

    try {
      await axios.put(apiUrl + `/api/inmuebles/destacar/${id}`, null, {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      Swal.fire({
        icon: "success",
        title: "Éxito",
        background: "#202529",
        color: "white",
        confirmButtonColor: "#2980b9",
        text: "El inmueble se destacó correctamente",
      });
    } catch (error) {
      Sentry.captureException(error);
      Swal.fire({
        icon: "error",
        title: "Error, intente de nuevo.",
        background: "#202529",
        color: "white",
        confirmButtonColor: "#2980b9",
      });
    }
  };
  const filteredProperties = properties.filter((property) => {
    if (searchValue.trim() === "") {
      return true; // Mostrar todos los inmuebles si no hay búsqueda
    } else {
      const searchId = Number(searchValue);
      return !isNaN(searchId) && property.id === searchId;
    }
  });

  return (
    <div>
      <Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
        <InputGroup className="custom-input-group mt-3">
          <InputGroup.Text>
            <FaSearch size="1em" color="#fcd088" />
          </InputGroup.Text>

          <FormControl
            placeholder="Buscar inmueble por código"
            aria-label="Buscar inmueble"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </InputGroup>
      </Col>
      {!isLoading ? (
        <div className="cards-container">
          {filteredProperties.length > 0 ? (
            filteredProperties.map((property) => (
              <Card
                key={property.id}
                style={{
                  border: property.destacado
                    ? "1px solid rgba(252, 78, 57,0.2)"
                    : "none",
                  boxShadow: property.destacado
                    ? "0 0 4px #fcd088"
                    : "0 0 4px white",

                  backgroundColor: "rgba(56,65,73,0.15)",
                  color: "white",
                }}
              >
                {property.destacado ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      backgroundColor: "#fcd088",
                      color: "#202529",
                      textAlign: "center",
                      padding: "1px 100px",
                      width: "100%",
                      fontSize: "13px",
                      fontWeight: "bold",
                      transformOrigin: "left bottom",
                      zIndex: 2,
                      boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    Destacado
                  </div>
                ) : null}
                <Card.Body>
                  <Card.Title>{property.titulo}</Card.Title>
                  <Card.Text className="border-bottom pb-1 fw-light">
                    Propietario: {property.username}
                  </Card.Text>
                  Código: {property.id} <br />
                  Direccion: {property.direccion} <br />
                </Card.Body>
                <Card.Footer className="border-top" style={{ padding: 20 }}>
                  {!property.destacado && (
                    <a
                      className="inicio2"
                      onClick={() => {
                        if (!property.destacado) {
                          destacarInmueble(property.id);
                        }
                      }}
                    >
                      <FaStar style={{ marginRight: 10 }} />
                      Destacar
                    </a>
                  )}

                  <a
                    className="inicio2"
                    onClick={() => successMessage(property.id)}
                    style={{ marginLeft: 10 }}
                  >
                    <FaTrash style={{ marginRight: 10 }}></FaTrash>
                    Borrar
                  </a>
                </Card.Footer>
              </Card>
            ))
          ) : (
            <div
              className="text-center d-flex justify-content-center align-items-center"
              style={{ marginTop: "100px" }}
            >
              <img
                src={imagen}
                alt="Sin resultados"
                style={{ maxWidth: "150px" }}
              />
              <p style={{ color: "white" }}>
                Parece que no hay <strong>inmuebles</strong> registrados.
              </p>
            </div>
          )}
        </div>
      ) : (
        <Container className="d-flex flex-column align-items-center justify-content-center vh-100">
          <div className="loader"></div>
        </Container>
      )}
    </div>
  );
};

export default Inmuebles;
