/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import {
  Card,
  Button,
  Pagination,
  Container,
  Modal,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  FaUserPlus,
  FaUserSlash,
  FaUserTimes,
  FaDollarSign,
  FaSearch,
} from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import config from "./../../../../../config.json";
import imagen from "../../../../../public/images/no_result_inmueble.png";
import PaginationBar from "../../FiltradoPage/Paginacion/Paginacion";
const apiUrl = config.apiUrl;
import * as Sentry from "@sentry/react";

const Particulares = ({ jwt }) => {
  const [isLoading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [selectedID, setSelectedID] = useState("");

  const fechaActual = new Date();

  // Añadir un mes a la fecha actual
  fechaActual.setMonth(fechaActual.getMonth() + 1);

  // Formatear la fecha a YYYY-MM-DD
  const fechaFormateada = fechaActual.toISOString().split("T")[0];

  // Inicializar el estado con la fecha actual + 1 mes
  const [selectedDate, setSelectedDate] = useState(fechaFormateada);

  const [plan, setPlan] = useState("BÁSICO");

  const handleClose = () => {
    setShow(false);
    setSelectedID("");
  };

  const handleAceptarPago = async (id, fecha, plan) => {
    try {
      const parts = fecha.split("-");
      const formattedFecha = `${parts[2]}/${parts[1]}/${parts[0]}`;

      await axios.put(
        apiUrl + `/api/user/payok/${id}`,
        { fechapago: formattedFecha, plan: plan },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      showSuccessAlert("El pago se aceptó correctamente");
      handleClose();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Hubo un error al aceptar el pago:", error);
      showErrorAlert();
    }
  };

  //Chip para usuarios activados
  const chipActivado = {
    backgroundColor: "green", // Color de fondo del chip
    color: "white", // Color del texto dentro del chip
    padding: "3px 10px 3px", // Espaciado dentro del chip
    borderRadius: "15px", // Bordes redondeados para el chip
    display: "inline-block", // Mostrar como inline-block para que el chip se ajuste al contenido
    fontSize: "11px",
    marginLeft: "10px",
  };

  //Chip para usuarios desactivados
  const chipDesactivado = {
    backgroundColor: "orange", // Color de fondo del chip
    color: "white", // Color del texto dentro del chip
    padding: "3px 10px 3px", // Espaciado dentro del chip
    borderRadius: "15px", // Bordes redondeados para el chip
    display: "inline-block", // Mostrar como inline-block para que el chip se ajuste al contenido
    marginBottom: "5px",
    fontSize: "11px",
    marginLeft: "10px",
  };

  const chipPlanUsuario = {
    backgroundColor: "#fcd088", // Un color verde para representar el plan activo
    color: "#202529", // Color del texto dentro del chip
    padding: "4px 12px", // Espaciado dentro del chip
    borderRadius: "20px", // Bordes más redondeados para el chip
    display: "inline-block", // Mostrar como inline-block para que el chip se ajuste al contenido
    marginBottom: "5px",
    maxWidth: "150px",
    fontSize: "12px", // Un poco más grande para destacar
    marginLeft: "10px",
    marginTop: "10px",
    fontWeight: "bold", // Texto en negrita para mayor énfasis
    boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.2)", // Añadir una sombra ligera para profundidad
  };

  const handleShow = (id) => {
    setShow(true);
    setSelectedID(id);
  };

  const handleActivateUser = async (id) => {
    try {
      const response = await axios.put(
        apiUrl + `/api/user/blockuser/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      showSuccessAlert("El usuario se activo correctamente.");
    } catch (error) {
      Sentry.captureException(error);
      console.error("Hubo un error al activar usuario:", error);
      showErrorAlert();
      // Resto del código de manejo de errores
    }
  };

  const handleDeleteProperty = async (id) => {
    try {
      await axios.delete(apiUrl + `/api/users/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      // Actualizar la lista de propiedades después de eliminar una
    } catch (error) {
      Sentry.captureException(error);
      console.error("Hubo un error al eliminar usuario", error);
      // Resto del código de manejo de errores
    }
  };

  const [properties, setProperties] = useState([]);

  const showSuccessAlert = (message) => {
    Swal.fire({
      icon: "success",
      title: "Éxito",
      text: message,
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  const showErrorAlert = () => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Hubo un error al realizar la operación. Por favor, intenta de nuevo.",
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  const successMessage = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Esta seguro que desea eliminar el usuario?",
        text: "!No puedes revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleDeleteProperty(id);
          swalWithBootstrapButtons.fire("Usuario eliminado correctamente");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado");
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          apiUrl + `/api/users?filters[tipo]=Particular`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        const propertiesData = response.data.map((property) => ({
          id: property.id,
          ...property,
        }));
        setProperties(propertiesData);

        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        console.error("Hubo un error con la petición:", error);
      }
    };
    fetchData();
  }, []);

  const [searchValue, setSearchValue] = useState("");

  const filteredProperties = properties.filter((property) =>
    property.email.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div>
      <Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
        <InputGroup className="custom-input-group mt-3">
          <InputGroup.Text>
            <FaSearch size="1em" color="#fcd088" />
          </InputGroup.Text>

          <FormControl
            placeholder="Buscar particular por correo"
            aria-label="Buscar inmueble"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </InputGroup>
      </Col>
      {!isLoading ? (
        <div className="cards-container">
          {filteredProperties.length > 0 ? (
            filteredProperties.map((property) => (
              <Card
                key={property.id}
                style={{
                  border: property.destacado
                    ? "1px solid rgba(252, 78, 57,0.2)"
                    : "none",
                  boxShadow: property.destacado
                    ? "0 0 4px #fcd088"
                    : "0 0 4px white",
                  minHeight: "500px",
                  backgroundColor: "rgba(56,65,73,0.15)",
                  color: "white",
                }}
              >
                {property.plan ? (
                  <span style={chipPlanUsuario} className="text-center">
                    Plan {property.plan}
                  </span>
                ) : null}

                <Card.Body>
                  <Card.Title>
                    {property.username}

                    {property.blocked ? (
                      <span style={chipDesactivado}>Desactivado</span>
                    ) : (
                      <span style={chipActivado}>Activado</span>
                    )}
                  </Card.Title>
                  <Card.Text></Card.Text>
                  Email: {property.email} <br />
                  Nombre: {property.nombre} <br />
                  Apellido: {property.apellido} <br />
                  Telefono: {property.telefono} <br />
                  Confirmado: {property.confirmed ? "Sí" : "No"} <br />
                  Bloqueado: {property.blocked ? "Sí" : "No"} <br />
                  Estado: {property.estado} <br />
                  Fecha pago:{" "}
                  {property.fechapago
                    ? new Date(property.fechapago).toLocaleDateString()
                    : "Sin fecha"}{" "}
                  <br />
                </Card.Body>
                <Card.Footer className="border-top">
                  <Row style={{ marginBottom: 5 }}>
                    <a
                      onClick={handleShow.bind(null, property.id)}
                      className="inicio2"
                      style={{ width: "100%" }}
                    >
                      <FaDollarSign style={{ marginRight: 10 }}></FaDollarSign>
                      Agregar pago
                    </a>
                  </Row>
                  {property.blocked ? (
                    // Si property.blocked es true, muestra solo el botón de "Activar usuario"
                    <Row style={{ marginBottom: 5 }}>
                      <a
                        onClick={() => handleActivateUser(property.id)}
                        className="inicio2"
                        style={{
                          width: "100%",
                          color: "#aab7b8",
                          borderColor: "#aab7b8",
                        }}
                      >
                        <FaUserPlus style={{ marginRight: 10 }}></FaUserPlus>
                        Activar usuario
                      </a>
                    </Row>
                  ) : (
                    // Si property.blocked es false, muestra solo el botón de "Desactivar usuario"
                    <Row style={{ marginBottom: 5 }}>
                      <a
                        onClick={() => handleActivateUser(property.id)}
                        className="inicio2"
                        style={{
                          width: "100%",
                          color: "#aab7b8",
                          borderColor: "#aab7b8",
                        }}
                      >
                        <FaUserSlash style={{ marginRight: 10 }}></FaUserSlash>
                        Desactivar usuario
                      </a>
                    </Row>
                  )}
                  {/* Botón para eliminar usuario */}
                  <Row style={{ marginBottom: 5 }}>
                    <a
                      onClick={() => successMessage(property.id)}
                      className="inicio2"
                      style={{
                        width: "100%",
                        color: "#e74c3c",
                        borderColor: "#e74c3c",
                      }}
                    >
                      <FaUserTimes style={{ marginRight: 10 }}></FaUserTimes>
                      Eliminar usuario
                    </a>
                  </Row>
                </Card.Footer>
              </Card>
            ))
          ) : (
            <div
              className="text-center d-flex justify-content-center align-items-center"
              style={{ marginTop: "100px" }}
            >
              <img
                src={imagen}
                alt="Sin resultados"
                style={{ maxWidth: "150px" }}
              />
              <p style={{ color: "white" }}>
                Parece que no hay <strong>particulares</strong> registrados.
              </p>
            </div>
          )}
        </div>
      ) : (
        <Container className="d-flex flex-column align-items-center justify-content-center vh-100 ">
          <div className="loader"></div>
        </Container>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        style={{ backgroundColor: "rgba(32,37,41,0.75)" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar fecha de pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label className="fw-bold">Seleccionar plan</Form.Label>
            <Form.Control
              as="select"
              custom
              onChange={(e) => setPlan(e.target.value)}
              className="mb-2"
            >
              <option value="BÁSICO">Básico</option>
              <option value="PREMIUM">Premium</option>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label className="fw-bold mb-2">Fecha de pago</Form.Label>
            <Form.Control
              type="date"
              name="datepic"
              placeholder="DateRange"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose} className="button-35">
            Cerrar
          </Button>
          <Button
            variant="success"
            className="button-34"
            onClick={() => handleAceptarPago(selectedID, selectedDate, plan)}
          >
            Guardar pago
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Particulares;
