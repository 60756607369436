/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import {
  Container,
  Row,
  Col,
  // eslint-disable-next-line no-unused-vars
  Image,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { FaSave, FaEdit, FaRegTimesCircle } from "react-icons/fa";
import axios from "axios";
import { useEffect, useState } from "react";
import "./Perfil.css";
import config from "./../../../../../config.json";
const apiUrl = config.apiUrl;
import * as Sentry from "@sentry/react";

const UserProfile = ({ iduser, jwt }) => {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);

  const chipEmail = {
    backgroundColor: "white", // Color de fondo del chip
    color: "black", // Color del texto dentro del chip
    padding: "3px 10px 3px", // Espaciado dentro del chip
    borderRadius: "15px", // Bordes redondeados para el chip
    display: "inline-block", // Mostrar como inline-block para que el chip se ajuste al contenido
    fontSize: "15px",
    marginTop: "10px",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          apiUrl + `/api/user/getUserById/${iduser}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        const userData = {
          ...response.data,
        };
        setUser(userData);
        setEditedUser(userData);
        setIsLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        console.error("Hubo un error con la petición:", error);
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iduser]);

  const handleEditProfile = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSaveProfile = async () => {
    try {
      await axios.put(apiUrl + `/api/users/${editedUser.id}`, editedUser, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setIsEditing(false);
      setUser(editedUser);
      setIsLoading(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error("Hubo un error al guardar el perfil:", error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Container className="d-flex flex-column align-items-center justify-content-center vh-100 ">
        <div className="loader"></div>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col
          md={12}
          className="perfil w-90 m-auto card mt-4 mb-5"
          style={{
            maxWidth: "900px",
            backgroundColor: "transparent",
            color: "white",
            borderColor: "#fcd088",
          }}
        >
          <div className="card-title border-bottom p-1">
            <h2 style={chipEmail}>{user.email}</h2>
          </div>
          {user.tipo === "Inmobiliaria" ? (
            <>
              <p>
                <strong>Razon Social</strong>
                {isEditing ? (
                  <Form.Group
                    className="custom-input-group"
                    controlId="razonsocial"
                  >
                    <Form.Control
                      type="text"
                      name="razonsocial"
                      value={editedUser.razonsocial}
                      onChange={handleChange}
                    />
                  </Form.Group>
                ) : (
                  <p className="mt-2">{user.razonsocial}</p>
                )}
              </p>
            </>
          ) : (
            <>
              <p>
                <strong className="pb-3">Nombre</strong>
                {isEditing ? (
                  <Form.Group className="custom-input-group" controlId="nombre">
                    <Form.Control
                      type="text"
                      name="nombre"
                      value={editedUser.nombre}
                      onChange={handleChange}
                    />
                  </Form.Group>
                ) : (
                  <p className="mt-2">{user.nombre}</p>
                )}
              </p>
              <p>
                <strong>Apellido</strong>
                {isEditing ? (
                  <Form.Group
                    className="custom-input-group"
                    controlId="apellido"
                  >
                    <Form.Control
                      type="text"
                      name="apellido"
                      value={editedUser.apellido}
                      onChange={handleChange}
                    />
                  </Form.Group>
                ) : (
                  <p className="mt-2">{user.apellido}</p>
                )}
              </p>
            </>
          )}
          <p>
            <strong>Telefono</strong>
            {isEditing ? (
              <Form.Group className="custom-input-group" controlId="telefono">
                <Form.Control
                  type="text"
                  name="telefono"
                  value={editedUser.telefono}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              <p className="mt-2">{user.telefono}</p>
            )}
          </p>

          {isEditing ? (
            <Row className="border-top" style={{ padding: 30 }}>
              <Col xs={6}>
                <a
                  onClick={handleSaveProfile}
                  className="inicio w-75 text-nowrap"
                >
                  <FaSave style={{ marginRight: 10 }}></FaSave>Guardar
                </a>
              </Col>
              <Col xs={6}>
                <a
                  onClick={() => setIsEditing(false)}
                  className="inicio w-75 text-nowrap"
                >
                  <FaRegTimesCircle
                    style={{ marginRight: 10 }}
                  ></FaRegTimesCircle>
                  Cancelar
                </a>
              </Col>
            </Row>
          ) : (
            <Row className="border-top">
              <a
                onClick={handleEditProfile}
                className="w-50 m-auto mb-3 inicio  mt-3"
              >
                <FaEdit style={{ marginRight: "5px" }}></FaEdit> Editar Perfil
              </a>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
