/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import {
  Container,
  Row,
  Col,
  // eslint-disable-next-line no-unused-vars
  Image,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import {
  FaSave,
  FaEdit,
  FaRegTimesCircle,
  FaHome,
  FaKey,
  FaUserAlt,
} from "react-icons/fa";
import axios from "axios";
import { useEffect, useState } from "react";
import "./Perfil.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import config from "./../../../../../config.json";
const apiUrl = config.apiUrl;
import * as Sentry from "@sentry/react";

// Luego puedes usar 'apiUrl' en tus peticiones HTTP
const chipEmail = {
  backgroundColor: "white", // Color de fondo del chip
  color: "black", // Color del texto dentro del chip
  padding: "3px 10px 3px", // Espaciado dentro del chip
  borderRadius: "15px", // Bordes redondeados para el chip
  display: "inline-block", // Mostrar como inline-block para que el chip se ajuste al contenido
  fontSize: "15px",
  marginTop: "10px",
};

const UserProfile = ({ iduser, jwt }) => {
  const [user, setUser] = useState(null);
  const [fechaPago, setFechaPago] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const showSuccessAlert = (message) => {
    Swal.fire({
      icon: "success",
      title: "Éxito",
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
      text: message,
    });
  };

  const showErrorAlert = () => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Hubo un error al realizar la operación. Por favor, intenta de nuevo.",
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          apiUrl + `/api/user/getUserById/${iduser}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        const userData = {
          ...response.data,
        };
        userData["fechapago"] = formatoLegible(userData.fechapago);
        setUser(userData);
        setEditedUser(userData);
        setIsLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsLoading(false);

        if (error.response.status == 403) {
          navigate("/userblock");
        }
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iduser]);

  const handleEditProfile = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSaveProfile = async () => {
    try {
      await axios.put(apiUrl + `/api/user/me`, editedUser, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setIsEditing(false);
      setUser(editedUser);
      setIsLoading(false);
      showSuccessAlert("Los datos de perfil se actualizaron exitosamente.");
    } catch (error) {
      Sentry.captureException(error);
      console.error("Hubo un error al guardar el perfil:", error);
      setIsLoading(false);
      showErrorAlert();
    }
  };

  if (isLoading) {
    return (
      <Container className="d-flex flex-column align-items-center justify-content-center vh-100 ">
        <div className="loader"></div>
      </Container>
    );
  }

  function formatoLegible(fecha) {
    // Parsear la fecha
    const fechaParseada = new Date(fecha);

    // Opciones para formatear la fecha
    const opciones = { year: "numeric", month: "long", day: "numeric" };

    // Formatear la fecha en el formato local
    return fechaParseada.toLocaleDateString("es-ES", opciones);
  }

  return (
    <Container>
      <Row>
        <Col
          md={12}
          className="perfil w-100 m-auto card mt-4 mb-5"
          style={{
            maxWidth: "700px",
            backgroundColor: "transparent",
            color: "white",
            borderColor: "#fcd088",
          }}
        >
          <div className="card-title mx-auto row w-100 border-bottom pr-3 pl-3">
            <div className="col-md-4 d-flex justify-content-center align-items-center p-2">
              <h2 style={{ fontSize: "16px", marginLeft: 20 }}>{user.email}</h2>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center p-2">
              <h2 style={{ fontSize: "16px" }}>
                Plan <span style={{ color: "#fcd088" }}>{user.plan}</span>{" "}
              </h2>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center p-2">
              <h2 style={{ fontSize: "16px" }}>
                Prox. pago <br />{" "}
                <span style={{ color: "#fcd088" }}>{user.fechapago}</span>{" "}
              </h2>
            </div>
          </div>

          <p style={{ marginTop: "0.5rem" }}>
            <strong style={{ color: "#fcd088" }}>Tipo</strong>
            {isEditing ? (
              <Form.Group className="custom-input-group" controlId="tipo">
                <Form.Control
                  type="text"
                  name="tipo"
                  value={editedUser.tipo}
                  onChange={handleChange}
                  className="input"
                  readOnly
                  style={{ textAlign: "center" }}
                />
              </Form.Group>
            ) : (
              <p className="mt-2 ">{user.tipo}</p>
            )}
          </p>
          {user.tipo === "Inmobiliaria" ? (
            <>
              <p>
                <strong style={{ color: "#fcd088" }}>Razon Social</strong>
                {isEditing ? (
                  <Form.Group
                    className="custom-input-group"
                    controlId="razonsocial"
                  >
                    <Form.Control
                      type="text"
                      name="razonsocial"
                      value={editedUser.razonsocial}
                      onChange={handleChange}
                      className="input"
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>
                ) : (
                  <p className="mt-2">{user.razonsocial}</p>
                )}
              </p>
            </>
          ) : (
            <>
              <p>
                <strong style={{ color: "#fcd088" }}>Nombre</strong>
                {isEditing ? (
                  <Form.Group className="custom-input-group" controlId="nombre">
                    <Form.Control
                      type="text"
                      name="nombre"
                      value={editedUser.nombre}
                      onChange={handleChange}
                      className="input"
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>
                ) : (
                  <p className="mt-2">{user.nombre}</p>
                )}
              </p>
              <p>
                <strong style={{ color: "#fcd088" }}>Apellido</strong>
                {isEditing ? (
                  <Form.Group
                    className="custom-input-group"
                    controlId="apellido"
                  >
                    <Form.Control
                      type="text"
                      name="apellido"
                      value={editedUser.apellido}
                      onChange={handleChange}
                      className="input"
                      style={{ textAlign: "center" }}
                    />
                  </Form.Group>
                ) : (
                  <p className="mt-2">{user.apellido}</p>
                )}
              </p>
            </>
          )}
          <p>
            <strong style={{ color: "#fcd088" }}>Telefono</strong>
            {isEditing ? (
              <Form.Group className="custom-input-group" controlId="telefono">
                <Form.Control
                  type="text"
                  name="telefono"
                  value={editedUser.telefono}
                  onChange={handleChange}
                  className="input"
                  style={{ textAlign: "center" }}
                />
              </Form.Group>
            ) : (
              <p className="mt-2">{user.telefono}</p>
            )}
          </p>
          <p>
            <strong style={{ color: "#fcd088" }}>Estado</strong>
            {isEditing ? (
              <Form.Group className="custom-input-group " controlId="estado">
                <Form.Control
                  type="text"
                  name="estado"
                  value={editedUser.estado}
                  onChange={handleChange}
                  className="input"
                  readOnly
                  style={{ textAlign: "center" }}
                />
              </Form.Group>
            ) : (
              <p className="mt-2">{user.estado}</p>
            )}
          </p>

          {isEditing ? (
            <>
              <Row className="border-top" style={{ padding: 20 }}>
                <Col xs={6} style={{ marginTop: 7 }}>
                  <a
                    onClick={handleSaveProfile}
                    className="botonmasdetalles w-75 text-nowrap"
                    style={{}}
                  >
                    <FaSave style={{ marginRight: 10 }}></FaSave>Guardar
                  </a>
                </Col>
                <Col xs={6} style={{ marginTop: 7 }}>
                  <a
                    onClick={() => setIsEditing(false)}
                    className="botonmasdetalles w-75 text-nowrap"
                  >
                    <FaRegTimesCircle
                      style={{ marginRight: 10 }}
                    ></FaRegTimesCircle>
                    Cancelar
                  </a>
                </Col>
              </Row>
            </>
          ) : (
            <Row className="border-top">
              <a
                onClick={handleEditProfile}
                className="w-50 m-auto mb-3 inicio mt-3"
              >
                <FaEdit style={{ marginRight: "5px" }}></FaEdit> Editar Perfil
              </a>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
