/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { Modal, Form, InputGroup, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import config from "./../../../config.json";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import * as Sentry from "@sentry/react";

const apiUrl = config.apiUrl;

const recuperarpassword = () => {
  const [password, setPassword] = useState("");
  const [repeatpassword, setRepeatPassword] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [fullscreen] = useState(true);
  const [params, setParams] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search).get("code");
    if (params) {
      setParams(params);
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    if (password === repeatpassword) {
      axios
        .post(apiUrl + "/api/auth/reset-password", {
          password: password,
          passwordConfirmation: repeatpassword,
          code: params,
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Exito,",
            background: "#202529",
            color: "white",
            confirmButtonColor: "#2980b9",
            text: "Se ha actualizado correctamente la contraseña.",
          });
          navigate("/");
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Error",
        background: "#202529",
        color: "white",
        confirmButtonColor: "#2980b9",
        text: "Las contraseñas no coinciden",
      });
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="x-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={fullscreen}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <h3 className="text-center mt-5" style={{ color: "#fcd088" }}>
            Alquilarg.com
          </h3>
          <h4 className="text-center pb-5 pt-4">Recuperar contraseña</h4>
          <div className="sliding-auth-form container-login-admin">
            <Form onSubmit={handleSubmitLogin}>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  {showPassword ? (
                    <FaEyeSlash
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <FaEye onClick={() => setShowPassword(!showPassword)} />
                  )}
                </InputGroup.Text>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Ingresa tu nueva contraseña"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </InputGroup>
              <InputGroup className="mb-3" controlid="formPassword">
                <InputGroup.Text>
                  {showPassword ? (
                    <FaEyeSlash
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <FaEye onClick={() => setShowPassword(!showPassword)} />
                  )}
                </InputGroup.Text>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Repite la nueva contraseña"
                  value={repeatpassword}
                  onChange={handleRepeatPasswordChange}
                  className="input-admin"
                />
              </InputGroup>
              <Button
                variant="primary"
                type="submit"
                className="input-admin button-38"
              >
                Reestablecer la contraseña
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default recuperarpassword;
