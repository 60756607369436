import { useState, useEffect } from "react";
import { Navbar, Nav, Button, Modal, Container } from "react-bootstrap";
import "./NavBar.css";
import SlidingAuthForm from "../../../../pages/registro-login/registro-login";
import Logo from "../../../images/LogoInmosMini.png";
import { FaUser } from "react-icons/fa";

const NavBar = () => {
  const [show, setShow] = useState(false);
  const [navBackground, setNavBackground] = useState("transparent");
  const [borderBottom, setBorderBottom] = useState("transparent");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !location.pathname.includes("/busqueda") &&
        !location.pathname.includes("/inmueble")
      ) {
        const offset = window.scrollY;
        const isScrolled = offset > window.innerHeight;
        setNavBackground(isScrolled ? "rgba(32,37,41,0.5)" : "transparent");
        setBorderBottom(
          isScrolled ? "0.25px solid rgba(255,255,255,0.5)" : "transparent"
        );
      }
    };

    const handleRouteChange = () => {
      const isBusqueda =
        location.pathname.includes("/busqueda") ||
        location.pathname.includes("/inmueble");
      setNavBackground(isBusqueda ? "rgba(32,37,41)" : "transparent");
      setBorderBottom(isBusqueda ? "transparent" : "transparent");
    };

    handleRouteChange(); // Aplicar los estilos cuando la página cargue

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("popstate", handleRouteChange); // Detectar cambios en la ruta (navegación)

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        style={{
          backgroundColor: navBackground,
          transition: "background-color 0.3s ease",
          borderBottom: borderBottom,
        }}
        fixed={
          location.pathname.includes("/busqueda") ||
          location.pathname.includes("/inmueble") ||
          location.pathname.includes("/inmobiliaria")
            ? ""
            : "top"
        }
      >
        <Container>
          <Navbar.Brand
            href="/"
            style={{ color: "#fcd088", fontWeight: "bold" }}
          >
            <img src={Logo} alt="Logo" />
          </Navbar.Brand>

          <Navbar.Brand>
            <a
              className="botonmasdetalles"
              onClick={handleShow}
              style={{
                backgroundColor: "transparent",
                color: "#ecf0f1",
                borderColor: "#ecf0f1",
              }}
            >
              <FaUser style={{ marginRight: 15 }} />
              INGRESAR
            </a>
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{ backgroundColor: "#202529", border: "none", color: "white" }}
        ></Modal.Header>
        <Modal.Body style={{ backgroundColor: "#202529" }}>
          <SlidingAuthForm />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavBar;
