import { Container, Row, Col, Card } from "react-bootstrap";
import image1 from "../../../images/s1.png";
import image2 from "../../../images/s2.png";
import image3 from "../../../images/s3.png";
import image4 from "../../../images/s4.png";
import image5 from "../../../images/s5.png";
import image6 from "../../../images/s6.png";
import im from "../../../images/servi.png";

const Servicios = () => {
  const servicios = [
    {
      imgSrc: image1,
      title: "Obtenga su propia web",
      description: "En InmoS le brindamos el sitio web para su inmobiliaria",
    },
    {
      imgSrc: image2,
      title: "Haga crecer su empresa",
      description:
        "Hoy en día, NO tener un sitio web significa falta de presencia, InmoS es la opción",
    },
    {
      imgSrc: image3,
      title: "Ahorre tiempo y dinero",
      description:
        "Ahorre tiempo y costo de alojamiento, dominio y diseño, InmoS es su sitio web YA!",
    },
    {
      imgSrc: image4,
      title: "El detalle hace la diferencia",
      description:
        "Con InmoS podrá mostrar todo lo que tiene para ofrecer con lujo de detalle",
    },
    {
      imgSrc: image5,
      title: "Organice su negocio",
      description:
        "ImnoS le permite almacenar y organizar toda la información de cada inmueble",
    },
    {
      imgSrc: image6,
      title: "Comunicación constante",
      description:
        "Apuntamos a optimizar la comunicación entre su inmobiliaria y su cliente",
    },
  ];

  return (
    <section
      id="atframework_services-3"
      style={{
        position: "relative",
        overflow: "hidden", // Asegúrate de que los elementos no se salgan del contenedor
      }}
    >
      <div
        style={{
          position: "relative",
          height: "100%", // Asegura que el div de la imagen ocupe todo el espacio
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${im})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            zIndex: "-1",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)", // Negro con opacidad
            zIndex: "0", // Asegura que este div esté sobre la imagen
          }}
        />
        <Container style={{ padding: 10, position: "relative", zIndex: "1" }}>
          <h2 className="titulo" style={{ marginBottom: 30 }}>
            SERVICIOS
          </h2>
          <Row>
            {servicios.map((servicio, index) => (
              <Col md={4} className="mb-4" key={index}>
                <Card
                  className="h-100 text-center"
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <Card.Img
                    variant="top"
                    src={servicio.imgSrc}
                    alt={servicio.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      margin: "0 auto",
                      paddingTop: "20px",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>
                      <span
                        className="titulo"
                        style={{ color: "white", textTransform: "uppercase" }}
                      >
                        {servicio.title}
                      </span>
                    </Card.Title>
                    <Card.Text>
                      <p className="titulo" style={{ color: "white" }}>
                        {servicio.description}
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Servicios;
