import NavBar from "./assets/components/HomePage/NavBar/NavBar";
import Busqueda from "./assets/components/HomePage/Busqueda/Busqueda";
import Servicios from "././assets/components/HomePage/Servicios/Servicios";
import Footer from "././assets/components/HomePage/Footer/Footer";
import Opiniones from "./assets/components/HomePage/Opiniones/Opiniones";
import MapaLaPlata from "./assets/components/HomePage/Mapa/Mapa";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <NavBar></NavBar>
      <Busqueda></Busqueda>
      <MapaLaPlata></MapaLaPlata>
      <Servicios></Servicios>
      <Opiniones></Opiniones>
      <Footer></Footer>
    </>
  );
}

export default App;
