import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  InputGroup,
  Form,
  Button,
  Container,
  Col,
  Image,
} from "react-bootstrap"; // Importa los componentes de Reactstrap
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "./../../../config.json";
import { FaBuilding, FaGoogle, FaPhone } from "react-icons/fa";
import Imagen from "../../assets/images/favicon.png";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";

const apiUrl = config.apiUrl;

const cardStyle = {
  width: "500px",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  margin: "0 auto",
  marginTop: "20px",
};

const RegistrationStep2 = () => {
  const { userType } = useParams();

  const navigate = useNavigate();

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [razonSocial, setRazonsocial] = useState("");
  const [telefono, setTelefono] = useState("");

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const email = queryParams.get("email");

  const handleApellidoChange = (e) => {
    setApellido(e.target.value);
  };

  const handleNombreChange = (e) => {
    setNombre(e.target.value);
  };

  const handleRazonSocialChange = (e) => {
    setRazonsocial(e.target.value);
  };

  const handleTelefonoChange = (e) => {
    setTelefono(e.target.value);
  };

  const handleSaveProfile = async (e) => {
    e.preventDefault();

    const jwt = document.cookie
      .split("; ")
      .find((row) => row.startsWith("jwt="))
      .split("=")[1];

    const newUser = {
      tipo: userType,
      telefono: telefono,
      estado: "No pago",
      freedays: true,
    };

    let valid = true; // Bandera para rastrear si la validación es exitosa

    if (!validatePhoneNumber(telefono)) {
      showErrorAlert("El teléfono ingresado no es válido.");
      valid = false;
    } else if (userType === "Particular") {
      if (!nombre || !apellido) {
        showErrorAlert("Por favor ingresa tu nombre y apellido.");
        valid = false;
      }
      newUser.nombre = nombre;
      newUser.apellido = apellido;
    } else {
      if (!razonSocial) {
        showErrorAlert("Por favor ingresa la razón social.");
        valid = false;
      }
      newUser.razonsocial = razonSocial;
    }

    if (valid) {
      try {
        const response = await axios.put(`${apiUrl}/api/user/me`, newUser, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        const emailData = {
          email: email,
        };

        axios.post(apiUrl + "/api/user/sendmailreg", emailData, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        navigate("/panelusuario", {
          state: { data: response.data.data, jwt: jwt },
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error("Hubo un error al guardar el perfil:", error);
        showErrorAlert(
          "Hubo un error al realizar la operación. Por favor, intenta de nuevo."
        );
      }
    }
  };

  const showErrorAlert = (errorMessage) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: errorMessage,
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberLength = phoneNumber.replace(/-/g, "").length;

    const validLengths = [10, 11];

    return validLengths.includes(phoneNumberLength);
  };

  const handlePrevious = () => {
    // Navegar hacia atrás en el historial del navegador
    window.history.back();
  };

  return (
    <Container className="d-flex justify-content-center align-items-center h-100">
      <div style={cardStyle}>
        <Col>
          <Image src={Imagen} roundedCircle className="w-25 h-25" />
        </Col>
        <h1 style={{ color: "#fcd088", fontWeight: "bold" }}>AlquilARG</h1>
        <h5 className="text-secondary">Registro con Google</h5>
        <h5>
          <FaGoogle></FaGoogle>
        </h5>
        <h6 className="text-secondary">Paso 2 de 2</h6>
        <div style={{ fontWeight: "bold" }} className="mb-3 mt-3">
          Información
        </div>
        {userType === "Particular" ? (
          <>
            <Form className="" onSubmit={handleSaveProfile}>
              <InputGroup className="mb-3 w-200 m-auto">
                <InputGroup.Text id="basic-addon-name">Nombre</InputGroup.Text>
                <Form.Control
                  type="name"
                  placeholder="Ingresa tu nombre"
                  onChange={handleNombreChange}
                />
              </InputGroup>

              <InputGroup className="mb-3 w-200 m-auto">
                <InputGroup.Text id="basic-addon-apellido">
                  Apellido
                </InputGroup.Text>
                <Form.Control
                  type="name"
                  placeholder="Ingresa tu apellido"
                  onChange={handleApellidoChange}
                />
              </InputGroup>

              <div className="mb-1 mt-2">Telefono</div>
              <InputGroup className="mb-3 w-75 m-auto">
                <InputGroup.Text id="basic-addon-telefono">
                  Telefono
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Ingresa tu telefono"
                  onChange={handleTelefonoChange}
                />
              </InputGroup>

              <Button
                style={{ backgroundColor: "#fcd088" }}
                type="submit"
                className="button-38"
              >
                Registrarme
              </Button>
            </Form>
          </>
        ) : (
          <Form onSubmit={handleSaveProfile}>
            <InputGroup className="mb-3 w-200 m-auto">
              <InputGroup.Text id="basic-addon-razon">
                Razón social
              </InputGroup.Text>
              <Form.Control
                type="name"
                placeholder="Ingresa tu razón social"
                onChange={handleRazonSocialChange}
              />
            </InputGroup>

            <InputGroup className="mb-3 w-200 m-auto">
              <InputGroup.Text id="basic-addon-telefono">
                Telefono
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Ingresa tu telefono"
                onChange={handleTelefonoChange}
              />
            </InputGroup>

            <Button
              style={{ backgroundColor: "#fcd088" }}
              type="submit"
              className="button-38"
            >
              Registrarme
            </Button>
          </Form>
        )}
        <Button
          variant="dark"
          className="button-39"
          type="submit"
          onClick={handlePrevious}
        >
          Atrás
        </Button>
      </div>
    </Container>
  );
};

export default RegistrationStep2;
