import { Container, Row, Col, Button } from "react-bootstrap";
import "./error-page.css";
import { Link } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";

function ErrorPage() {
  return (
    <div className="error-page">
      <Container>
        <Row>
          <Col style={{ color: "white" }}>
            <FaExclamationTriangle size={48} />
            <h1 className="error-title">Oops!</h1>
            <p className="error-message">Lo sentimos, ha ocurrido un error.</p>
            <Button variant="black" className="error-button">
              <Link className="error-link" to="/">
                Volver a la página principal
              </Link>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ErrorPage;
