import { useState, useEffect } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NavBar from "../../assets/components/HomePage/NavBar/NavBar";
import config from "./../../../config.json";
import { Helmet } from "react-helmet-async";
import imagen from "../../assets/images/no_result_inmueble.png";
const apiUrl = config.apiUrl;
import * as Sentry from "@sentry/react";
import { useMediaQuery } from "react-responsive";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  FaArrowLeft,
  FaCity,
  FaEnvelope,
  FaHome,
  FaInfoCircle,
  FaMap,
  FaMapPin,
  FaMoneyBill,
  FaPaperPlane,
  FaPhone,
  FaPlus,
  FaWhatsapp,
} from "react-icons/fa";
import Swal from "sweetalert2";
import "./inmobiliaria.css";

const Inmobiliaria = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [inmo, setInmo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");

  async function handleSubmit(e) {
    e.preventDefault(); // Esto evita la recarga de la página

    const formData = new FormData();
    formData.append("nombre", nombre);
    formData.append("correo", email);
    formData.append("telefono", telefono);
    formData.append("mensaje", mensaje);

    try {
      axios.post(apiUrl + `/api/inmuebles/comunicarcorreo/${id}`, formData, {});

      showSuccessAlert();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error al enviar correo", error);
      throw error;
    }

    setShowModal(false); // Cierra el modal después del envío
  }

  const showSuccessAlert = () => {
    Swal.fire({
      icon: "success",
      title: "¡Correo enviado correctamente!",
      text: "El propietario se contará contigo lo antes posible",
      timer: 5000,
      background: "#202529",
      color: "white",
      confirmButtonColor: "#2980b9",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl + `/api/user/page/${id}`);
        if (response.data.data.length === 0) {
          setError(true);
          setLoading(false);
          return;
        }
        const inmoData = response.data.data;
        setInmo(inmoData);
        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        console.error("Hubo un error con la petición:", error);
        setError(true);
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleVolver = () => {
    navigate(`/busqueda`);
  };

  const handleMessageWhatsApp = () => {
    // Mensaje predeterminado
    const currentUrl = window.location.href;
    const message = `¡Hola! ${inmo.razonsocial} \n\n ${currentUrl}`;

    // URL para abrir WhatsApp con el mensaje predefinido
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${
      inmo.telefono
    }&text=${encodeURIComponent(message)}`;

    // Redirigir a la URL de WhatsApp
    window.open(whatsappUrl, "_blank");
  };

  if (loading) {
    return (
      <Container className="d-flex flex-column align-items-center justify-content-center vh-100 ">
        <div className="loader"></div>
      </Container>
    );
  }

  if (!inmo) {
    return (
      <>
        <NavBar />
        <Container className="mt-5 text-center" style={{ color: "white" }}>
          <img
            src={imagen}
            alt="Sin resultados"
            style={{ maxWidth: "300px" }}
          />
          <h3>La inmobiliaria que está buscando no existe</h3>
          <h6 style={{ marginBottom: 30 }}>
            Verifique el código que ingresó o intente nuevamente
          </h6>
          <a href="/busqueda" className="inicio">
            Volver
          </a>
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <NavBar />
        <Container className="mt-5 text-center" style={{ color: "white" }}>
          <h3>Hubo un error al cargar la información</h3>
          <Button
            as={Link}
            to="/busqueda"
            variant="danger"
            className="button-35"
          >
            Volver
          </Button>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{inmo.razonsocial || inmo.nombre}</title>
        <meta property="og:title" content={inmo.razonsocial} />
        <meta
          property="og:url"
          content={`https://inmos.com.ar/inmobiliaria/${id}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <NavBar></NavBar>
      <Container className="mt-3">
        <Row>
          <Col xs={12} md={12}>
            <Card
              style={{
                paddingLeft: "0.5rem",
                color: "white",
                backgroundColor: "transparent",
                border: "0.5px solid #fcd088",
              }}
            >
              <Row className="justify-content-start">
                <Col md={2} className="d-flex justify-content-center">
                  <a
                    className="inicio"
                    onClick={() => {
                      handleVolver();
                    }}
                    style={{
                      width: "90%",
                      textAlign: "start",
                      marginTop: 10,
                      border: "none",
                    }}
                  >
                    <FaArrowLeft style={{ marginRight: 10 }} />
                    ATRÁS
                  </a>
                </Col>
              </Row>
              <Row className="justify-content-center">
                {!isMobile && inmo.tipo == "Inmobiliaria" && (
                  <Col
                    md={3}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <img
                      src={inmo.logourl}
                      alt={inmo.razonsocial}
                      className="img-fluid"
                    />
                  </Col>
                )}
                <Col
                  md={6}
                  className={
                    !isMobile
                      ? "d-flex flex-column justify-content-center align-items-start"
                      : "d-flex flex-column justify-content-center align-items-center"
                  }
                >
                  <h1
                    style={{
                      textTransform: "uppercase",
                      marginTop: isMobile ? 40 : 0,
                      color: "#fcd088",
                      marginBottom: 20,
                    }}
                  >
                    {inmo.tipo == "Particular"
                      ? `${inmo.apellido} ${inmo.nombre}`
                      : inmo.razonsocial}
                  </h1>
                  <p>
                    <FaPhone style={{ marginRight: 10, color: "#fcd088" }} />{" "}
                    {inmo.telefono}
                  </p>
                  <p>
                    <FaCity style={{ marginRight: 10, color: "#fcd088" }} />{" "}
                    {inmo.cantidadInmuebles} Inmuebles
                  </p>
                </Col>
                {isMobile && (
                  <Col
                    md={3}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <img
                      src={inmo.logourl}
                      alt={inmo.razonsocial}
                      className="img-fluid"
                      width={200}
                      height={200}
                    />
                  </Col>
                )}
                <Row className="justify-content-center mb-3">
                  <Col md={6} className="d-flex justify-content-center">
                    <a
                      className="inicio"
                      onClick={() => {
                        handleMessageWhatsApp();
                      }}
                      style={{
                        width: "90%",
                        textAlign: "center",
                        marginTop: 10,
                      }}
                    >
                      <FaWhatsapp style={{ marginRight: 10 }} />
                      Whatsapp
                    </a>
                  </Col>

                  <Col md={6} className="d-flex justify-content-center">
                    <a
                      className="inicio"
                      onClick={() => setShowModal(true)}
                      style={{
                        width: "90%",
                        textAlign: "center",
                        marginTop: 10,
                      }}
                    >
                      <FaEnvelope style={{ marginRight: 10 }} />
                      Correo
                    </a>
                  </Col>
                </Row>
              </Row>
            </Card>
          </Col>
        </Row>
        {inmo.inmuebles.length != 0 && (
          <Container className="mt-4" style={{ marginBottom: 30 }}>
            <span
              style={{
                color: "white",
                textTransform: "uppercase",
                fontSize: 22,
              }}
            >
              Inmuebles
            </span>
            <p style={{ color: "white", fontSize: 14, marginBottom: 10 }}>
              <FaInfoCircle style={{ color: "#fcd088", marginRight: 10 }} />
              {isMobile ? "Toca " : "Desliza el mouse sobre "}el inmueble para
              ver mas detalles.
            </p>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={false}
              keyBoardControl={true}
              arrows={true}
            >
              {inmo.inmuebles.map((inmueble) => (
                <Card
                  key={inmueble.id}
                  className=" inmueble-card"
                  style={{
                    color: "white",
                    backgroundColor: "transparent",
                    border: "0.5px solid #fcd088",
                    marginTop: 10,
                    marginBottom: 40,
                    marginRight: 10,
                    marginLeft: 10,
                    overflow: "hidden",
                  }}
                >
                  <Carousel
                    responsive={{
                      mobile: { breakpoint: { max: 4000, min: 0 }, items: 1 },
                    }}
                    infinite={true}
                    showDots={false}
                    autoPlay={true}
                    arrows={false}
                  >
                    {inmueble.imagesurl.map((image, idx) => (
                      <img
                        key={idx}
                        src={image}
                        alt={`Imagen ${idx + 1} del inmueble`}
                        style={{
                          width: "100%",
                          height: "300px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                    ))}
                  </Carousel>

                  <Card.Body className="inmueble-details d-flex flex-column justify-content-center align-items-center">
                    <Row className="justify-content-center text-center">
                      <Col>
                        <span style={{ fontSize: 18, color: "#fcd088" }}>
                          <FaMapPin
                            style={{
                              color: "#fcd088",

                              marginRight: 10,
                            }}
                          />
                          {inmueble.direccion}
                        </span>
                      </Col>
                    </Row>
                    <Row className="justify-content-center text-center">
                      <Col>
                        <span style={{ fontSize: 18 }}>
                          <FaMap
                            style={{
                              color: "#fcd088",

                              marginRight: 10,
                            }}
                          />
                          {inmueble.localidad}
                        </span>
                      </Col>
                    </Row>
                    <Row className="justify-content-center text-center">
                      <Col>
                        <span>
                          <FaHome
                            style={{
                              color: "#fcd088",

                              marginRight: 10,
                            }}
                          />
                          {inmueble.tipo}
                        </span>
                      </Col>
                    </Row>
                    <Row className="justify-content-center text-center">
                      <Col>
                        <Row>
                          {inmueble.venta && (
                            <span>
                              <FaMoneyBill
                                style={{
                                  color: "#fcd088",

                                  marginRight: 10,
                                }}
                              />
                              {inmueble.venta && "Vende"}
                            </span>
                          )}
                          {inmueble.alquiler && (
                            <span>
                              <FaMoneyBill
                                style={{
                                  color: "#fcd088",

                                  marginRight: 10,
                                }}
                              />
                              {inmueble.alquiler && "Alquila"}
                            </span>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <a
                      className="inicio"
                      onClick={() => {
                        navigate(`/inmueble/${inmueble.id}`);
                      }}
                      style={{ marginTop: 10, padding: 10 }}
                    >
                      <FaPlus /> {isMobile ? "Ver mas" : "Mas información"}
                    </a>
                  </Card.Body>
                </Card>
              ))}
            </Carousel>
          </Container>
        )}
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header
          style={{
            backgroundColor: "#202529",
            border: "none",
            color: "white",
          }}
        >
          <Modal.Title>Enviar correo al anunciante</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#202529",
            border: "none",
            color: "white",
          }}
        >
          <Form>
            <Form.Group
              className="custom-input-group mb-3"
              controlId="formBasicName"
            >
              <Form.Control
                type="text"
                placeholder="Ingrese su nombre"
                required
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="custom-input-group mb-3"
              controlId="formBasicEmail"
            >
              <Form.Control
                type="email"
                placeholder="Dirección de correo electronico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="custom-input-group mb-3"
              controlId="formBasicPhone"
            >
              <Form.Control
                type="number"
                maxLength={11}
                placeholder="Número de teléfono"
                pattern="[0-9]*"
                title="Solo se permiten números"
                required
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="custom-input-group mb-3"
              controlId="formBasicMessage"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Ingrese su mensaje"
                required
                value={mensaje}
                onChange={(e) => setMensaje(e.target.value)}
              />{" "}
            </Form.Group>

            <a
              type="submit"
              className="inicio"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              <FaPaperPlane style={{ marginRight: 10 }} />
              Enviar
            </a>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Inmobiliaria;
