/* eslint-disable react/prop-types */

import Card from "react-bootstrap/Card";
// eslint-disable-next-line no-unused-vars
import { Button, Carousel, ListGroup, Row, Col } from "react-bootstrap";
import {
  FaPlus,
  FaSearchLocation,
  FaHome,
  FaFile,
  FaBed,
  FaBath,
  FaCar,
  FaExpand,
  FaMapPin,
  FaMap,
  FaMoneyBillWave,
} from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PropertyCard = ({ id, property }) => {
  const handleInmuebleSelect = () => {
    window.open(`/inmueble/${id}`, "_blank");
  };

  const formatNumber = (number) => {
    if (number) {
      return number.toLocaleString("es-ES");
    }
  };

  return (
    <div>
      {property.publicado && (
        <Card
          style={{
            border: property.destacado
              ? "1px solid rgba(252, 78, 57,0.2)"
              : "none",
            boxShadow: property.destacado ? "0 0 4px #fcd088" : "0 0 4px white",
            minHeight: "500px",
            backgroundColor: "rgba(56,65,73,0.15)",
            color: "white",
          }}
        >
          <Carousel interval={null}>
            {property.imagesurl &&
              property.imagesurl
                .slice()
                .sort((a, b) => a.localeCompare(b))
                .map((image, index) => (
                  <Carousel.Item
                    key={index}
                    onClick={handleInmuebleSelect}
                    style={{
                      cursor: "pointer",
                      borderTopRightRadius: "6px",
                      borderTopLeftRadius: "6px",
                      filter: "drop-shadow(0 0 5px rgba(244, 246, 247, 0.9))", // Ajuste del color al fondo
                      maskImage:
                        "linear-gradient(to bottom, rgba(244, 246, 247, 0.9) 80%, transparent 100%)", // Gradiente para una mejor transición
                    }}
                  >
                    <Card.ImgOverlay>
                      <Card.Title
                        translate="no"
                        style={{
                          backgroundColor: "rgba(32,37,41,0.85)",
                          maxWidth: "150px",
                          display: "flex",
                          float: "right",
                          borderRadius: "7px",
                          padding: "0.25rem",
                          color: "#fcd088",
                          textTransform: "uppercase",
                          fontSize: 16,
                        }}
                      >
                        {property.tipo}
                      </Card.Title>
                    </Card.ImgOverlay>

                    <img
                      className="imagencarta"
                      style={{
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                        borderTopLeftRadius: "3px",
                        borderTopRightRadius: "3px",
                      }}
                      src={image}
                      alt={`Imagen ${index + 1}`}
                    />

                    {property.destacado ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "70px",
                          left: "0px",
                          backgroundColor: "#fcd088",
                          color: "#202529",
                          textAlign: "center",
                          padding: "1px 35px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          transform: "rotate(-45deg)",
                          transformOrigin: "left bottom",
                          zIndex: 100,
                          boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        Destacado
                      </div>
                    ) : null}
                  </Carousel.Item>
                ))}
          </Carousel>
          <Card.Body className="mb-0 pb-0">
            <Card.Title style={{ fontSize: 13 }}>
              <FaMap style={{ color: "#fcd088", marginRight: 10 }} />
              {property.localidad}
            </Card.Title>
            <Card.Title style={{ fontSize: 13 }}>
              <FaMapPin style={{ color: "#fcd088", marginRight: 10 }} />
              {property.direccion}
            </Card.Title>

            <Card.Text>
              <Row>
                {property.alquiler && (
                  <Col style={{ fontSize: 12, whiteSpace: "nowrap" }}>
                    <Row>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-expensas">
                            Precio de alquiler
                          </Tooltip>
                        }
                      >
                        <p
                          className=""
                          style={{ fontSize: 12, color: "white" }}
                        >
                          Alquiler{" "}
                          <span style={{ color: "#fcd088", fontSize: 10 }}>
                            {property.moneda}
                          </span>{" "}
                          {formatNumber(property.precio)}
                        </p>
                      </OverlayTrigger>
                    </Row>
                  </Col>
                )}
                {property.venta && (
                  <Col style={{ fontSize: 13, whiteSpace: "nowrap" }}>
                    <Row>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-expensas">
                            Precio de venta
                          </Tooltip>
                        }
                      >
                        <p
                          className=""
                          style={{ fontSize: 13, color: "white" }}
                        >
                          Venta{" "}
                          <span style={{ color: "#fcd088", fontSize: 10 }}>
                            {property.monedaventa}
                          </span>{" "}
                          {formatNumber(property.precioventa)}
                        </p>
                      </OverlayTrigger>
                    </Row>
                  </Col>
                )}
              </Row>

              <Row>
                <Col
                  style={{
                    height: "20px",
                  }}
                  className="d-flex justify-content-center"
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-dormitorios">Dormitorios</Tooltip>
                    }
                  >
                    <p className="" style={{ fontSize: 13, color: "white" }}>
                      <FaBed style={{ color: "#fcd088", marginRight: 5 }} />
                      {property.dormitorios}
                    </p>
                  </OverlayTrigger>
                </Col>

                <Col
                  className="d-flex justify-content-center"
                  style={{
                    height: "20px",
                  }}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-banios">Baños</Tooltip>}
                  >
                    <p className="" style={{ fontSize: 13, color: "white" }}>
                      <FaBath style={{ color: "#fcd088", marginRight: 5 }} />
                      {property.banios}
                    </p>
                  </OverlayTrigger>
                </Col>

                <Col className="d-flex justify-content-center">
                  {property.cochera ? (
                    <Row>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-garaje">Garajes</Tooltip>}
                      >
                        <p
                          className=""
                          style={{ fontSize: 13, color: "white" }}
                        >
                          <FaCar style={{ color: "#fcd088", marginRight: 5 }} />
                          {property.cantgaraje}
                        </p>
                      </OverlayTrigger>
                    </Row>
                  ) : (
                    <Row>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-garaje">Garajes</Tooltip>}
                      >
                        <p
                          className=""
                          style={{ fontSize: 13, color: "white" }}
                        >
                          <FaCar style={{ color: "#fcd088", marginRight: 5 }} />
                          No
                        </p>
                      </OverlayTrigger>
                    </Row>
                  )}
                </Col>
              </Row>

              <Row
                className="d-flex align-items-center justify-content-center"
                style={{ padding: 20, textAlign: "center" }}
              >
                <Col md="auto">
                  <a
                    className="botonmasdetalles"
                    onClick={() => handleInmuebleSelect()}
                  >
                    <FaPlus style={{ marginRight: 10 }} />
                    Más detalles
                  </a>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default PropertyCard;
