import "./Busqueda.css";

import { useNavigate } from "react-router-dom";

import fondo from "../../../images/fondo.jpg";

const SearchComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="search-container">
      <img src={fondo} id="video-background"></img>
      <h4 className="header-subtext">Bienvenidos a INMOS</h4>
      <h3 className="header-text">#INMOBILIARIAS</h3>
      <a
        className="inicio"
        onClick={() => {
          navigate("/busqueda");
        }}
      >
        Buscar inmuebles
      </a>
    </div>
  );
};

export default SearchComponent;
