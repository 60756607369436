import { useState, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  Alert,
  ProgressBar,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import "./registro-login.css";
import config from "./../../../config.json";
import Swal from "sweetalert2";
import {
  FaCity,
  FaEnvelope,
  FaEye,
  FaEyeSlash,
  FaHome,
  FaKey,
  FaLink,
  FaPhone,
  FaUser,
} from "react-icons/fa";
import * as Sentry from "@sentry/react";
import GoogleButton from "react-google-button";

const apiUrl = config.apiUrl;

const handleGoogleLogin = () => {
  window.location.href = apiUrl + "/api/connect/google";
};

function SlidingAuthForm() {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [tipo, setTipo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [web, setWeb] = useState("");
  const [error, setError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [imagenInmobiliaria, setImagenInmobiliaria] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let errorTimer, successTimer;

    if (error) {
      errorTimer = setTimeout(() => {
        setError(""); // Limpiar mensaje de error después de 5 segundos
      }, 3000);
    }

    if (successMessage) {
      successTimer = setTimeout(() => {
        setSuccessMessage(""); // Limpiar mensaje de éxito después de 5 segundos
      }, 3000);
    }

    return () => {
      clearTimeout(errorTimer);
      clearTimeout(successTimer);
    };
  }, [error, successMessage]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Obtener el primer archivo seleccionado
    if (file) {
      // Si se ha seleccionado un archivo, podrías guardarlo en el estado o manejarlo como prefieras
      setImagenInmobiliaria(file); // Guardar el archivo en el estado
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    axios
      .post(apiUrl + "/api/auth/forgot-password", {
        email: email,
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Exito,",
          background: "#202529",
          color: "white",
          confirmButtonColor: "#2980b9",
          text: "Se ha enviado el correctamente el mail de reestablecimiento",
        });
        setActiveStep(0);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleWebChange = (e) => {
    setWeb(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleNombreChange = (e) => {
    setNombre(e.target.value);
  };

  const handleApellidoChange = (e) => {
    setApellido(e.target.value);
  };

  const handleTipoChange = (e) => {
    setTipo(e.target.value);
  };

  const handleRazonSocialChange = (e) => {
    setRazonSocial(e.target.value);
  };

  const handleTelefonoChange = (e) => {
    setTelefono(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Por favor, complete todos los campos");
      return;
    }

    if (email == "admin@gmail.com") {
      setErrorMessage("Hubo un error en el inicio de sesión"); // Mostrar mensaje de error
      setSuccessMessage(""); // Limpiar mensaje de éxito
      return;
    }

    setLoading(true); // Mostrar la barra de progreso

    axios
      .post(apiUrl + "/api/auth/local", {
        identifier: email,
        password: password,
      })
      .then((response) => {
        setLoading(false); // Ocultar la barra de progreso
        setSuccessMessage("Inicio de sesión exitoso"); // Mostrar mensaje de éxito
        setErrorMessage(""); // Limpiar mensaje de error
        const jwt = response.data.jwt;

        Cookies.set("jwt", jwt);
        navigate("/panelusuario", {
          state: { data: response.data.user, jwt: jwt },
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        setLoading(false); // Ocultar la barra de progreso
        setErrorMessage("Hubo un error en el inicio de sesión"); // Mostrar mensaje de error
        setSuccessMessage(""); // Limpiar mensaje de éxito

        if (
          error.response.data.error.message ==
          "Your account has been blocked by an administrator"
        ) {
          setErrorMessage(
            "Su cuenta ha sido inhabilitada por el administrador."
          ); // Mostrar mensaje de error
        }
      });
  };

  const handleSubmitRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      setLoading(false);
      return;
    }

    let userData = {};

    if (tipo === "Inmobiliaria") {
      if (
        !email ||
        !password ||
        !confirmPassword ||
        !tipo ||
        !telefono ||
        !razonSocial
      ) {
        setError("Por favor, complete todos los campos");
        setLoading(false);
        return;
      }
      // Subir imagen si es una inmobiliaria
      let url = "";
      if (imagenInmobiliaria) {
        url = await uploadImage(imagenInmobiliaria);
      }
      userData = {
        username: email,
        email: email,
        password: password,
        telefono: telefono,
        tipo: tipo,
        razonsocial: razonSocial,
        paginaweb: web,
        estado: "No pago",
        logourl: url,
      };
    } else if (tipo === "Particular") {
      if (
        !email ||
        !password ||
        !confirmPassword ||
        !tipo ||
        !telefono ||
        !nombre ||
        !apellido
      ) {
        setError("Por favor, complete todos los campos");
        setLoading(false);
        return;
      }
      userData = {
        username: email,
        email: email,
        password: password,
        telefono: telefono,
        tipo: tipo,
        nombre: nombre,
        apellido: apellido,
        paginaweb: web,
        estado: "No pago",
        freedays: true,
      };
    }

    try {
      const response = await axios.post(
        apiUrl + "/api/auth/local/register",
        userData
      );
      const jwt = response.data.jwt;

      // Enviar correo de confirmación
      await axios.post(
        apiUrl + "/api/user/sendmailreg",
        { email: email },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      setLoading(false);
      setSuccessMessage("Registro exitoso");
      setErrorMessage("");

      Cookies.set("jwt", jwt);
      navigate("/panelusuario", {
        state: { data: response.data.user, jwt: jwt },
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error("Hubo un error con la petición:", error);
      setLoading(false);
      setErrorMessage("Hubo un error en el registro");
      setSuccessMessage("");
    }
  };

  const handleFileButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  async function uploadImage(image) {
    try {
      const formData = new FormData();
      formData.append("images", image);

      const resp = await axios.post(
        `https://inmos-webapp.azurewebsites.net/api/v1/upload`,
        formData,
        {
          headers: {
            Authorization: "Bearer +c62*<VSuaO#7ius",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return resp.data.imagesUrl;
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(
        "No se pudo crear el usuario por un error en la imagen, intentelo nuevamente"
      );
    }
  }

  return (
    <Container style={{ color: "white", textTransform: "uppercase" }}>
      {activeStep === 0 && (
        <div className="sliding-auth-form">
          <h2 className="d-block mb-5">Iniciar sesión</h2>

          <Form onSubmit={handleSubmitLogin} className="w-75 m-auto">
            <InputGroup className="custom-input-group mb-1 m-auto">
              <InputGroup.Text id="basic-addon1">
                <FaEnvelope style={{ color: "white" }} />
              </InputGroup.Text>
              <Form.Control
                type="email"
                placeholder="Ingresa tu correo electrónico"
                value={email}
                onChange={handleEmailChange}
              />
            </InputGroup>

            <InputGroup className="custom-input-group mb-1">
              <InputGroup.Text>
                {showPassword ? (
                  <FaEyeSlash
                    style={{ color: "white" }}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <FaEye
                    style={{ color: "white" }}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </InputGroup.Text>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Ingresa tu contraseña"
                value={password}
                onChange={handlePasswordChange}
              />
            </InputGroup>
            <a
              onClick={handleSubmitLogin}
              type="submit"
              className="inicio w-100 mb-3"
            >
              Ingresar
            </a>
            {/* 
            <div className="d-flex align-items-center mt-1 m-auto">
              <div className="flex-grow-1 border-top border-secondary"></div>
              <p className="px-3 text-secondary">O inicia sesión con</p>
              <div className="flex-grow-1 border-top border-secondary"></div>
            </div> */}

            {/* <GoogleButton
              className="m-auto"
              label="Google"
              onClick={() => handleGoogleLogin()}
            /> */}

            {/* <FacebookLogin
                language="es_ES"
                cssClass="btn-facebook"
                icon="fa-facebook"
                textButton="   Facebook"
              ></FacebookLogin> */}

            <Button
              style={{ color: "grey" }}
              className="btn-custom w-50"
              variant="outline-dark"
              onClick={() => handleStepChange(2)}
            >
              Olvide mi contraseña
            </Button>

            <Button
              style={{ color: "grey", marginTop: "0.15rem" }}
              className="btn-custom w-100"
              variant="outline-dark"
              onClick={() => handleStepChange(1)}
            >
              ¿Necesitas una cuenta? Regístrate aquí
            </Button>
          </Form>
        </div>
      )}
      {activeStep === 1 && (
        <div className="sliding-auth-form">
          <h2 className="mb-3">Registro</h2>
          <Form onSubmit={handleSubmitRegister}>
            <InputGroup className="custom-input-group mb-1" controid="formTipo">
              <InputGroup.Text id="basic-addon1">
                <FaHome />
              </InputGroup.Text>
              <Form.Control
                as="select"
                value={tipo}
                onChange={handleTipoChange}
              >
                <option
                  value=""
                  style={{
                    color: "black",
                  }}
                >
                  Seleccionar tipo
                </option>
                <option
                  value="Inmobiliaria"
                  style={{
                    color: "black",
                  }}
                >
                  Inmobiliaria
                </option>
                <option
                  value="Particular"
                  style={{
                    color: "black",
                  }}
                >
                  Particular
                </option>
              </Form.Control>
            </InputGroup>
            {tipo === "Particular" && (
              <>
                <InputGroup
                  className="custom-input-group mb-1"
                  controlid="formNombre"
                >
                  <InputGroup.Text id="basic-addon1">
                    <FaUser />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa tu nombre"
                    value={nombre}
                    onChange={handleNombreChange}
                  />
                </InputGroup>
                <InputGroup
                  className="custom-input-group mb-1"
                  controlid="formApellido"
                >
                  <InputGroup.Text id="basic-addon1">
                    <FaUser />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa tu apellido"
                    value={apellido}
                    onChange={handleApellidoChange}
                  />
                </InputGroup>
              </>
            )}

            {tipo === "Inmobiliaria" && (
              <>
                <InputGroup
                  className="custom-input-group mb-1"
                  controlId="formRazonSocial"
                >
                  <InputGroup.Text id="basic-addon1">
                    <FaCity />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa la razón social"
                    value={razonSocial}
                    onChange={handleRazonSocialChange}
                  />
                </InputGroup>

                <p
                  style={{
                    marginTop: 10,
                    textAlign: "start",
                    marginBottom: 10,
                    color: "white",
                  }}
                >
                  Logo de la inmobiliaria
                </p>
                <Form.Group className="custom-input-group mb-3">
                  <div className="text-start" style={{ padding: 20 }}>
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <a
                      className="inicio2"
                      onClick={handleFileButtonClick}
                      style={{ color: "white", borderColor: "white" }}
                    >
                      Seleccionar Archivo
                    </a>
                    {imagenInmobiliaria && (
                      <span style={{ marginLeft: "10px" }}>
                        {imagenInmobiliaria.name}
                      </span>
                    )}
                  </div>
                </Form.Group>
              </>
            )}

            <InputGroup
              className="custom-input-group mb-1"
              controlid="formTelefono"
            >
              <InputGroup.Text id="basic-addon1">
                <FaPhone />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Ingresa tu teléfono"
                value={telefono}
                onChange={handleTelefonoChange}
              />
            </InputGroup>
            <InputGroup
              className="custom-input-group mb-1"
              controlid="formEmail"
            >
              <InputGroup.Text id="basic-addon1">
                <FaEnvelope />
              </InputGroup.Text>
              <Form.Control
                type="email"
                placeholder="Ingresa tu email"
                value={email}
                onChange={handleEmailChange}
              />
            </InputGroup>
            <InputGroup
              className="custom-input-group mb-1"
              controlid="formPassword"
            >
              <InputGroup.Text>
                {showPassword ? (
                  <FaEyeSlash onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <FaEye onClick={() => setShowPassword(!showPassword)} />
                )}
              </InputGroup.Text>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Ingresa tu contraseña"
                value={password}
                onChange={handlePasswordChange}
              />
            </InputGroup>
            <InputGroup
              className="custom-input-group mb-1"
              controlid="formConfirmPassword"
            >
              <InputGroup.Text>
                {showPassword ? (
                  <FaEyeSlash onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <FaEye onClick={() => setShowPassword(!showPassword)} />
                )}
              </InputGroup.Text>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Repite tu contraseña"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </InputGroup>
            {error && <Alert variant="danger">{error}</Alert>}

            <a
              onClick={handleSubmitRegister}
              type="submit"
              className="inicio w-75 mb-3"
            >
              Registrarse
            </a>
            {/* 
            <div className="d-flex align-items-center mt-1 m-auto">
              <div className="flex-grow-1 border-top border-secondary"></div>
              <p className="px-3 text-secondary">O registrate con</p>
              <div className="flex-grow-1 border-top border-secondary"></div>
            </div>

            <GoogleButton
              className="m-auto"
              label="Google"
              onClick={() => handleGoogleLogin()}
            /> */}

            {/* <FacebookLogin
                  language="es_ES"
                  cssClass="btn-facebook"
                  icon="fa-facebook"
                  textButton="   Facebook"
                ></FacebookLogin> */}

            <Button
              style={{ color: "grey" }}
              className="btn-custom"
              variant="outline-dark"
              onClick={() => handleStepChange(0)}
            >
              ¿Ya tienes una cuenta? Inicia sesión aquí
            </Button>
          </Form>
        </div>
      )}
      {activeStep === 2 && (
        <div className="sliding-auth-form">
          <h2 className="d-block mb-5">Recuperar contraseña</h2>

          <Form onSubmit={handleForgotPassword} className="w-75 m-auto">
            <InputGroup className="mb-3 m-auto " controlid="formEmail">
              <InputGroup.Text id="basic-addon1">📩</InputGroup.Text>
              <Form.Control
                type="email"
                placeholder="Ingresa tu correo electrónico"
                value={email}
                onChange={handleEmailChange}
              />
            </InputGroup>

            <a
              onClick={handleForgotPassword}
              type="submit"
              className="w-75 inicio"
            >
              Recuperar contraseña
            </a>

            <Button
              style={{ color: "grey" }}
              className="btn-custom w-50"
              variant="outline-dark"
              onClick={() => handleStepChange(0)}
            >
              Ya tengo una cuenta
            </Button>

            <Button
              style={{ color: "grey", marginTop: "0.15rem" }}
              className="btn-custom w-100"
              variant="outline-dark"
              onClick={() => handleStepChange(1)}
            >
              ¿Necesitas una cuenta? Regístrate aquí
            </Button>
          </Form>
        </div>
      )}
      {loading && <ProgressBar animated now={100} />}

      {successMessage && !loading && (
        <Alert
          variant="success"
          onClose={() => setSuccessMessage("")}
          dismissible
        >
          {successMessage}
        </Alert>
      )}

      {errorMessage && !loading && (
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
          {errorMessage}
        </Alert>
      )}
    </Container>
  );
}

export default SlidingAuthForm;
