import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Image, Col } from "react-bootstrap"; // Importa componentes de React Bootstrap
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../config.json";
import { FaExclamationCircle } from "react-icons/fa";
import Imagen from "../../assets/images/favicon.png";
import * as Sentry from "@sentry/react";

const cardStyle = {
  width: "300px",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  margin: "0 auto",
  marginTop: "100px",
  border: "1px solid red",
};

const UserBlocked = () => {
  const [auth, setAuth] = useState();
  // eslint-disable-next-line no-unused-vars
  const [selection, setSelection] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const apiUrl = config.apiUrl;

  useEffect(() => {
    // Verificar si la cookie existe al cargar el componente
    const tokenFromCookie = Cookies.get("jwt");

    if (!tokenFromCookie) {
      // Si la cookie no existe y no hay jwt en el state, redirigir a "/"
      Cookies.remove("username");
      navigate("/");
    }

    const jwtData = {
      token: tokenFromCookie,
    };

    axios
      .post(apiUrl + "/api/auth/checkJWT", jwtData, {
        headers: {
          Authorization: `Bearer ${tokenFromCookie}`,
        },
      })
      .then((response) => {
        if (response.data.blocked) {
          navigate("/userblock");
          return;
        } else {
          navigate("/panelusuario");
        }

        setJWT(tokenFromCookie);
      })
      .catch((error) => {
        Sentry.captureException(error);
        if (error.response.status == 401) {
          navigate("/userblock");
        }
      });
  }, navigate);

  return (
    <Container className="d-flex justify-content-center align-items-center h-100">
      <div style={cardStyle}>
        <Col className="mb-2">
          <Image src={Imagen} roundedCircle className="w-25 h-25" />
        </Col>
        <h1 style={{ color: "#fcd088", fontWeight: "bold" }}>InmoS</h1>
        <h6 className="text-danger">Usuario bloqueado</h6>
        <h5 style={{ marginTop: "50px" }}>
          <FaExclamationCircle
            style={{ marginRight: "5px" }}
            className="text-danger"
          ></FaExclamationCircle>{" "}
          El usuario se encuentra bloqueado.
        </h5>
        <p style={{ marginTop: "30px", marginBottom: "30px" }}>
          Contactese con el administrador para revertir la situación.
        </p>
        <a href="/">
          <h6>Volver al inicio</h6>
        </a>

        <p style={{ marginTop: "30px", marginBottom: "30px" }}>
          <strong>Atención:</strong> si su usario ya fue desbloqueado, presione{" "}
          <strong>F5</strong> para recargar la página.
        </p>
      </div>
    </Container>
  );
};

export default UserBlocked;
