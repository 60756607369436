import {
  Form,
  InputGroup,
  Button,
  Alert,
  ProgressBar,
  Modal,
  Navbar,
  Container,
  Nav,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import "./paneladministrador.css";
import axios from "axios";
import {
  FaCity,
  FaUsers,
  FaUser,
  FaUserCog,
  FaPowerOff,
  FaLockOpen,
  FaEnvelope,
  FaKey,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Inmuebles from "../../assets/components/PanelAdministrador/Inmuebles/Inmuebles";
import Inmobiliarios from "../../assets/components/PanelAdministrador/Inmobiliarios/Inmobiliarios";
import Particulares from "../../assets/components/PanelAdministrador/Particulares/Particulares";
import PerfilComponent from "../../assets/components/PanelAdministrador/Perfil/Perfil";
import config from "./../../../config.json";
import logo from "../../assets/images/LogoInmosMini.png";
import * as Sentry from "@sentry/react";

const apiUrl = config.apiUrl;

const PanelAdministrador = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [fullscreen] = useState(true);

  const navigate = useNavigate();

  const [activeComponent, setActiveComponent] = useState(null);

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  const handleLogout = () => {
    Cookies.remove("jwt");
    Cookies.remove("username");
    navigate("/");
  };

  useEffect(() => {
    let errorTimer;
    let successTimer;

    if (error) {
      clearTimeout(errorTimer); // Limpiar el temporizador anterior antes de establecer uno nuevo
      errorTimer = setTimeout(() => {
        setErrorMessage("");
        setError(""); // Limpiar mensaje de error después de 5 segundos
      }, 3000);
    }

    if (successMessage) {
      clearTimeout(successTimer); // Limpiar el temporizador anterior antes de establecer uno nuevo
      successTimer = setTimeout(() => {
        setSuccessMessage(""); // Limpiar mensaje de éxito después de 5 segundos
      }, 3000);
    }

    return () => {
      clearTimeout(errorTimer);
      clearTimeout(successTimer);
    };
  }, [error, successMessage]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Por favor, complete todos los campos");
      return;
    }

    setLoading(true); // Mostrar la barra de progreso

    axios
      .post(apiUrl + "/api/auth/adminlogin", {
        identifier: email,
        password: password,
      })
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        const jwt = response.data.jwt;
        Cookies.set("jwt", jwt);
        Cookies.set("username", response.data.user.id);
        setLoading(false); // Ocultar la barra de progreso
        setSuccessMessage("Inicio de sesión exitoso"); // Mostrar mensaje de éxito
        setErrorMessage(""); // Limpiar mensaje de error
        setShowModal(false);

        handleButtonClick("inmuebles");
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        Sentry.captureException(error);
        setLoading(false); // Ocultar la barra de progreso
        setErrorMessage("El correo o contraseña son incorrectos."); // Mostrar mensaje de error
        setSuccessMessage(""); // Limpiar mensaje de éxito
      });
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="x-lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={fullscreen}
      >
        <Modal.Body
          style={{ backgroundColor: "#202529", border: "none", color: "white" }}
        >
          <div className="d-flex justify-content-center pt-5">
            <img
              src={logo}
              alt=""
              height="auto"
              width="auto"
              className="text-center mx-auto"
            />
          </div>
          <h2
            className="text-center pb-4 pt-5 w-100 m-auto mb-4"
            style={{ fontSize: "20px", textTransform: "uppercase" }}
          >
            Ingreso de usuario
          </h2>

          <div className="sliding-auth-form container-login-admin">
            <Form onSubmit={handleSubmitLogin}>
              <p
                className="fw-bold text-start"
                style={{ fontSize: "14px", textTransform: "uppercase" }}
              >
                Correo
              </p>
              <InputGroup
                className="custom-input-group mb-3"
                controlid="formEmail"
              >
                <InputGroup.Text id="basic-addon1">
                  <FaEnvelope />
                </InputGroup.Text>
                <Form.Control
                  type="email"
                  placeholder="Ingresa tu correo electrónico"
                  value={email}
                  onChange={handleEmailChange}
                  className="input-admin"
                />
              </InputGroup>
              <p
                className="fw-bold text-start"
                style={{ fontSize: "14px", textTransform: "uppercase" }}
              >
                Contraseña
              </p>
              <InputGroup
                className="custom-input-group mb-3"
                controlid="formPassword"
              >
                <InputGroup.Text id="basic-addon1">
                  <FaKey />{" "}
                </InputGroup.Text>
                <Form.Control
                  type="password"
                  placeholder="Ingresa tu contraseña"
                  value={password}
                  onChange={handlePasswordChange}
                  className="input-admin"
                />
              </InputGroup>
              <a
                onClick={handleSubmitLogin}
                type="submit"
                className="input-admin inicio"
              >
                Ingresar
              </a>

              {loading && (
                <ProgressBar animated now={100} style={{ marginTop: 30 }} />
              )}

              {successMessage && !loading && (
                <Alert variant="success">{successMessage}</Alert>
              )}

              {errorMessage && !loading && (
                <Alert variant="danger">{errorMessage}</Alert>
              )}
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <Navbar collapseOnSelect expand="lg">
        <Container fluid style={{ paddingLeft: 50 }}>
          <Navbar.Brand
            style={{ color: "#fcd088", fontWeight: "bold", cursor: "pointer" }}
            href="/"
          >
            InmoS
          </Navbar.Brand>
          <div className="chip-avatar">
            <span className="chip-avatar-text">Administrador</span>
          </div>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ backgroundColor: "#fcd088", border: "1px solid black" }}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav className="me-auto">
              <a
                className={`inicio2 ${
                  activeComponent === "inmuebles" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("inmuebles")}
                style={{
                  border: "none",
                  color: "white",
                }}
              >
                <FaCity style={{ marginRight: 10 }}></FaCity>Inmuebles
              </a>
              <a
                style={{
                  border: "none",
                  color: "white",
                }}
                className={`inicio2 ${
                  activeComponent === "inmobiliarios" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("inmobiliarios")}
              >
                <FaUsers style={{ marginRight: 10 }}></FaUsers>Inmobiliarios
              </a>
              <a
                style={{
                  border: "none",
                  color: "white",
                }}
                className={`inicio2 ${
                  activeComponent === "particulares" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("particulares")}
              >
                <FaUser style={{ marginRight: 10 }}></FaUser>Particulares
              </a>

              <a
                className={`inicio2 ${
                  activeComponent === "perfil" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("perfil")}
                style={{
                  border: "none",
                  color: "white",
                }}
              >
                <FaUserCog style={{ marginRight: 10 }}></FaUserCog>Perfil
              </a>
            </Nav>
            <Nav>
              <a
                className={`inicio2 ${
                  activeComponent === "cerrar-sesion" ? "active" : ""
                }`}
                onClick={() => {
                  handleButtonClick("cerrar-sesion");
                  handleLogout(); // Llamada a la función para cerrar sesión
                }}
              >
                <FaPowerOff style={{ marginRight: 10 }} />
                Cerrar Sesión
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid className="mt-2">
        {activeComponent === "inmuebles" && (
          <Inmuebles jwt={Cookies.get("jwt")} />
        )}
        {activeComponent === "inmobiliarios" && (
          <Inmobiliarios jwt={Cookies.get("jwt")} />
        )}
        {activeComponent === "particulares" && (
          <Particulares jwt={Cookies.get("jwt")} />
        )}
        {activeComponent === "perfil" && (
          <PerfilComponent
            iduser={Cookies.get("username")}
            jwt={Cookies.get("jwt")}
          />
        )}
      </Container>
    </>
  );
};

export default PanelAdministrador;
