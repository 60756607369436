import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/error404/error-page.jsx";
import Filtrado from "./pages/filtrado/filtrado.jsx";
import PanelAdministrador from "./pages/paneladministrador/paneladministrador.jsx";
import PanelUsuario from "./pages/panelusuario/panelusuario.jsx";
import Inmueble from "./pages/inmueble/inmueble.jsx";
import GoogleAuthCallback from "./pages/googleauth/googlecallback.jsx";
import RegistrationStep2 from "./pages/pasosregistro/RegistrationStep2.jsx";
import UserBlocked from "./pages/userblocked/userblocked.jsx";
import Recuperarpassword from "./pages/recuperarpassword/recuperarpassword.jsx";
import { HelmetProvider } from "react-helmet-async";

import * as Sentry from "@sentry/react";
import Inmobiliaria from "./pages/inmobiliaria/Inmobiliaria.jsx";

Sentry.init({
  dsn: "https://3fd5c1783680167ce8789f17287719e2@o4507896740577280.ingest.us.sentry.io/4507896741822464",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [{}],
  },
  {
    path: "/busqueda",
    element: <Filtrado />,
  },
  {
    path: "/panelusuario",
    element: <PanelUsuario />,
  },
  {
    path: "/paneladmin",
    element: <PanelAdministrador />,
  },

  {
    path: "/inmueble/:id",
    element: <Inmueble />,
  },
  {
    path: "/auth/callback/google",
    element: <GoogleAuthCallback />,
  },
  {
    path: "/step2/:userType",
    element: <RegistrationStep2 />,
  },
  {
    path: "/userblock",
    element: <UserBlocked />,
  },
  {
    path: "/recuperarpassword",
    element: <Recuperarpassword />,
  },
  {
    path: "inmobiliaria/:id/:razonsocial",
    element: <Inmobiliaria />,
  },
]);

const helmetContext = {};

ReactDOM.createRoot(document.getElementById("root")).render(
  <HelmetProvider context={helmetContext}>
    <RouterProvider router={router} />
  </HelmetProvider>
);
